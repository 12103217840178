import Vue from 'vue';
import Router from 'vue-router';
import basicRouter from './basic';
import userRouter from './user';
import adminRouter from './admin';
import Meta from 'vue-meta';

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...basicRouter,
    ...userRouter,
    ...adminRouter,
    {
      path: '/401',
      name: 'Page401',
      component: () => import('../views/error-page/401')
    },
    {
      path: '/404',
      name: 'Page404',
      component: () => import('../views/error-page/404')
    },
    {path: '*', redirect: '/404'}
  ]
  // saveScrollPosition: true
  // scrollBehavior: () => ({y: 0})
});
export default router;
