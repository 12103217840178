<template>
  <div class="flash-sale cl center-box">
    <router-link class="item" target="_blank"
                 v-for="item of list"
                 :to="{name:'PromotionsDetail',params:{id:item.promotionId}}"
                 :key="item.promotionId">
      <div class="cover">
        <img class="img" :src="item.photoUrl" alt=""/>
      </div>
      <div class="infos">
        <div class="title">{{item.title}}</div>
        <div class="cont">{{item.introduction|delHtmlTag}}</div>

      </div>
    </router-link>
  </div>
</template>
<script>
  export default {
    name: 'Promotions',
    props: {
      list: Array
    }
  };
</script>
<style scoped lang="less">
  .center-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .flash-sale{
    padding-top:30px;
    .item{
      float:left;width:465px;margin-top:30px;margin-right:15px;margin-left:15px;overflow:hidden;color:#321908;
    }
    .cover{width:100%;height:250px;overflow:hidden;border-radius:4px;}
    .infos{margin-left:2px;}
    .title{padding-top:16px;padding-bottom:12px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-size:20px;font-weight: 500;}
    .cont{display:-webkit-box;height:44px;line-height:22px;overflow:hidden;-webkit-line-clamp:5;-webkit-box-orient:vertical;text-overflow: ellipsis;white-space: inherit;}
    .btn-detail{display:block;width:106px;height:40px;margin-top:12px;text-align:center;line-height:38px;color:#ff6f61;font-size:16px;border:1px solid #ff6f61;border-radius:4px;}
  }
</style>
