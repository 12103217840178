<template>
  <div id="app" class="wrapper">
    <page-header/>
    <transition name="fade">
      <router-view/>
    </transition>
    <page-footer/>
    <el-backtop>
      <i class="el-icon-caret-top"></i>
    </el-backtop>
  </div>
</template>
<script>
  import PageHeader from '../components/admin/PageHeader';
  import PageFooter from '../components/PageFooter';

  export default {
    name: 'Admin',
    components: {PageHeader, PageFooter}
  };
</script>
<!--<style scoped>-->
<!--  .wrapper >>> .layout{width:1200px;}-->
<!--  .wrapper >>> .footer{background:#c1bab4;}-->
<!--  .fade-enter-active{transition:opacity .5s;}-->
<!--  .fade-enter{opacity:0;}-->
<!--</style>-->
