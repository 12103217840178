import Vue from 'vue';
import {
  Autocomplete,
  Pagination,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Loading,
  MessageBox,
  Message,
  Select,
  Option,
  Carousel,
  CarouselItem,
  DatePicker,
  Slider,
  Rate,
  Upload,
  Input,
  Tooltip,
  Backtop,
  Dialog,
  Button,
  InputNumber,
  Progress,
  Popover,
  Tree,
  Col,
  Row,
  Container,
  Aside,
  Main,
  Header,
  drawer,
  Divider
} from 'element-ui';

import VueAwesomeSwiper from 'vue-awesome-swiper';
import App from './App.vue';
import router from './router';
import './router/permission';
import './utils/request';
import * as filters from './filters';
import i18n from './i18n';
import store from './store';
import Modal from './components/Modal';
import Tabs from './components/Tabs/Tabs';
import TabPane from './components/Tabs/TabPane';

import 'normalize.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'swiper/dist/css/swiper.css';
import './assets/style/common.less';

import './utils/Blob';
import './utils/Export2Excel';

import 'moment-timezone'; // 导入模块
import Print from 'vue-print-nb';
import moment from 'moment';
import VModal from 'vue-js-modal';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;

// 实例化Vue的filter
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
// 註冊
Vue.use(VueMeta);
Vue.use(VModal, {componentName: 'modalJS'});
Vue.use(VueAwesomeSwiper, {});
Vue.use(Autocomplete);
Vue.use(Pagination);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(DatePicker);
Vue.use(Slider);
Vue.use(Rate);
Vue.use(Upload);
Vue.use(Input);
Vue.use(Tooltip);
Vue.use(Backtop);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(InputNumber);
Vue.use(Progress);
Vue.use(Popover);
Vue.use(Tree);
Vue.use(Loading.directive);
Vue.use(Print);
Vue.use(moment);
Vue.use(Col);
Vue.use(Row);
Vue.use(Container);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Header);
Vue.use(drawer);
Vue.use(Divider);

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;
Vue.prototype.moment = moment;

Vue.component('Modal', Modal);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
Vue.component(VueQrcode.name, VueQrcode);

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');
