import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 获取最新消息列表
export function getMessageList(data) {
  return request({
    url: `/message/list/${language}/message`,
    method: 'get',
    params: data
  });
}

// 获取最新优惠列表
export function getOfferList(data) {
  return request({
    url: `/message/list/${language}/offer`,
    method: 'get',
    params: data
  });
}

// 消息置顶/已读操作
export function setMessage(messageId, operateType = 'top') {
  return request({
    url: `/message/operate/${messageId}/${operateType}`,
    method: 'post'
  });
}

// 删除消息
export function deleteMessage(messageId) {
  return request({
    url: `/message/delete/${messageId}`,
    method: 'post'
  });
}

  // 获取站内消息列表
export function getNoticeMessageList(data) {
    return request({
      url: `/noticeMessage/list`,
      method: 'post',
      data
    });
  }

// 获取站内消息未读数量
  export function getNoticeMessageCount(data) {
    return request({
      url: `/noticeMessage/count`,
      method: 'post',
      data
    });
  }

// 更改站内消息
  export function updateNoticeMessage(data) {
    return request({
      url: `/noticeMessage/update`,
      method: 'post',
      data
    });
}
