<template>
  <div class="footer">
    <div class="layout">
      <div class="txt-c">
        <div class="subscription">
          <!-- 隱藏最新質訊
          <input class="fl ipt w1" type="text" autocomplete="off" :placeholder="$t('placeholderName')"
                 v-model.trim="userName"/>
          <input class="fl ipt" type="text" autocomplete="off" :placeholder="$t('placeholderEMail')"
                 v-model.trim="userEmail"/>
          <a class="fl btn-submit" href="javascript:;" @click="onSubmit">{{$t('btnSubscription')}}</a>
          -->
        </div>
      </div>
      <div class="foot-link">
        <el-row class="inner cl center-box">
          <el-col :xs="24" :sm="12" :span="12" class="introduction" >
            <!-- icon and introduction text -->
            <div class="introduction-icon">
              <img class="fl introduction-left" width="95" src="../assets/images/logo-footer.png"/>
            </div>
            <div class="introduction-right">
              <span>{{$t('introduction')}}</span>
              <div class="links">
                <a href="https://www.facebook.com/unutravel/" title="Facebook" target="_blank">
                  <img src="../assets/images/icon/facebook.png" width="16" alt=""/>
                </a>
                <a href="https://www.instagram.com/unutravel/" title="Instagram" target="_blank">
                  <img width="16" src="../assets/images/icon/instagram.png" alt=""/>
                </a>
                <a href="https://www.youtube.com/channel/UC4mztN1DJAEW_iShm1LIVXg" title="YouTube" target="_blank">
                  <img src="../assets/images/icon/youtube.png" alt=""/>
                </a>
                <el-popover popper-class="footer-qr-code"
                            placement="top"
                            trigger="hover">
                  <div class="qr-code">
                    <img class="img" src="../assets/images/wechat-qrcode.jpg"/>
                  </div>
                  <a slot="reference">
                    <img width="16" src="../assets/images/icon/wechat@2x.png" alt=""/>
                  </a>
                </el-popover>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :span="12" class="fr right-aligned">
            <dl class="fl link-group">
              <dt>{{$t('yiyou')}}</dt>
              <dd>
                <router-link :to="{name:'About',params:{type:'aboutus'}}">{{$t('aboutus')}}</router-link>
              </dd>
              <dd>
                <a href="https://wa.me/85226160676">{{$t('contactUs')}}</a>
<!--                <router-link :to="{name:'About',params:{type:'contactus'}}">{{$t('contactUs')}}</router-link>-->
              </dd>
            </dl>
            <dl class="fl link-group">
              <dt>{{$t('partner')}}</dt>
              <dd v-if="roleType === 'root'">
                <router-link :title="$t('MerchantLogin')" :to="{name:adminToken? 'AdminMy':'AdminLogin'}">
                  {{adminToken? $t('MyMerchantAccount'): $t('MerchantLogin')}}
                </router-link>
              </dd>
              <dd v-else>
                <router-link :title="$t('MerchantLogin')" :to="{path:adminToken? menuUrl[0]:'/admin/login'}">
                  {{adminToken? $t('MyMerchantAccount'): $t('MerchantLogin')}}
                </router-link>
              </dd>
              <dd>
                <router-link :to="{name:'Joinus'}" :title="$t('joinPlatform')">{{$t('joinPlatform')}}
                </router-link>
              </dd>
              <dd>
                <a href="https://wa.me/85226160676">{{$t('advertisingNegotiation')}}</a>
<!--                <a href="https://unutravel.com/about/contactus" :title="$t('advertisingNegotiation')">{{$t('advertisingNegotiation')}}</a>-->
              </dd>
            </dl>
            <dl class="fl link-group">
              <dt>{{$t('websiteTerms')}}</dt>
              <dd>
                <router-link :to="{name:'About',params:{type:'TermsAndConditions'}}">{{$t('termsOfUse')}}</router-link>
              </dd>
              <dd>
                <router-link :to="{name:'About',params:{type:'privacy'}}">{{$t('privacyPolicy')}}</router-link>
              </dd>
              <dd>
                <router-link :to="{name:'About',params:{type:'cookie'}}">{{$t('cookiePolicy')}}</router-link>
              </dd>
              <dd>
                <router-link :to="{name:'About',params:{type:'refund'}}">{{$t('refundPolicy')}}</router-link>
              </dd>
            </dl>
          </el-col>
        </el-row>
        <el-row style="padding-bottom: 30px">
          <el-col :xs="24" :span="12" class="right-aligned fr">
            <dl class="fl link-group">
              <dt>{{$t('Can use payment method')}}</dt>
              <dd>
                <img height="30" width="100" src="../assets/images/payment/PayPal.jpeg" alt="PayPal accepted" style="padding-right: 10px"/>
                <img height="25" width="100" src="../assets/images/payment/alipayhk.jpeg" alt="alipayhk accepted" style="padding-right: 10px"/>
                <img height="30" width="100" src="../assets/images/payment/wechatPay.jpeg" alt="wechatPay accepted" style="padding-right: 10px"/>
                <img height="30" width="70" src="../assets/images/payment/UnionPay_logo.png" alt="UnionPay accepted" style="padding-right: 10px"/>
              </dd>
            </dl>
          </el-col>
        </el-row>
        <copyright/>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import Copyright from './Copyright';
  import {isEmail} from '../utils/validate';
  import {addContact} from '../api/common';

  export default {
    name: 'PageFooter',
    i18n: {
      messages: {
        'zh-cn': {
          introduction: 'UNU Travel（一游）网上旅游交易平台度身为旅行社设计，协助旅行社升级转型，旅客足不出户就可以即时自由选购心仪的旅行团或旅游产品。'
        },
        'zh-hk': {
          introduction: 'UNU Travel（一遊）網上旅遊交易平台度身為旅行社設計，協助旅行社升級轉型，旅客足不出戶就可以即時自由選購心儀的旅行團或旅遊產品。'
        },
        'en': {
          introduction: 'UNU Travel is a free travel matching platform that brings together travel agencies and travelers from Hong Kong and all over the world. '
        }
      }
    },
    data() {
      return {
        userName: '',
        userEmail: ''
      };
    },
    components: {Copyright},
    computed: {...mapGetters(['adminToken', 'roleType', 'menuUrl'])},
    methods: {
      onSubmit() {
        const data = {
          contactType: 'news',
          userName: this.userName,
          userEmail: this.userEmail
        };

        if (!data.userName) {
          this.$message.warning(this.$t('Please fill out', [this.$t('Name')]));
          return false;
        }
        if (!isEmail(data.userEmail)) {
          this.$message.warning(this.$t('PleaseEnterTheCorrectEmail'));
          return false;
        }

        addContact(data).then(res => {
          this.$message.success('提交成功！');
          this.userName = '';
          this.userEmail = '';
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .introduction-left{
    margin-right: 10px;
  }
  .introduction-right{
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  .right-aligned{
    display: flex;
    justify-content: flex-end;
  }
  .center-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .footer{
    position:relative;width:100%;height:388px;margin-top:30px;background:url('../assets/images/bg-footer.jpg') no-repeat top center;
    &:after{position:absolute;top:0;right:0;bottom:0;left:0;background-color:rgba(50, 25, 8, 0.3);content:'';}
    > .layout{position:relative;z-index:1;overflow:hidden;}
  }
  .subscription{
    display:inline-block;height:48px;margin:60px auto 0;vertical-align:top;overflow:hidden;background-color:#fff;border-radius:4px;
    .ipt{
      width:300px;height:48px;padding:0 20px;border:none;
      &.w1{width:160px;border-right:1px solid #999;}
    }
    .btn-submit{display:block;width:120px;height:48px;line-height:48px;text-align:center;color:#fff;font-size:16px;background-color:#ff6f61;}
  }
  .foot-link{
    margin-top:42px;overflow:hidden;background-color:#fff;border-radius:0;
    .inner{padding:32px;}
  }
  .link-group{
    margin-left:56px;
    dt{padding-bottom:6px;}
    dd{margin-top:8px;}
    a{
      color:#999;
      &:hover{text-decoration:underline;}
    }
  }
  .link-group:first-child{
    margin-left: 0;
  }
  .introduction{
    display: flex;
    justify-content: row;
    margin:10px 0 0 0;
    .links{
      a{display:inline-block;margin-right:16px;}
    }
    p{color:#999; font-size:13px}
  }
  .qr-code{width:160px;height:160px;}
  @media only screen and (max-width: 768px) {
    .right-aligned{
      margin-top: 20px;
      justify-content: center;
    }
    .subscription{
      display: none;
    }
    .footer{
      height: auto;
    }
    .foot-link{
      margin-top: 0;
    }
  }
</style>
