<template>
  <div id="app" class="wrapper">
    <page-header/>
    <keep-alive :include="['Home','Featured','Travels']">
      <router-view/>
    </keep-alive>
    <page-footer/>
    <el-backtop>
      <i class="el-icon-caret-top"></i>
    </el-backtop>
  </div>
</template>
<script>
  import PageHeader from '../components/PageHeader';
  import PageFooter from '../components/PageFooter';

  export default {
    name: 'Basic',
    components: {PageHeader, PageFooter}
  };
</script>
