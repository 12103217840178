<template>
  <div v-if="list&&list.length">
    <div class="hd">{{$t('Hot destinations')}}</div>
    <div class="hots cl center-box">
      <router-link class="item ani-scale" v-for="item of list" :to="{name:'ThemeTravel',query:{cityId:item.businessId}}" :key="item.id">
        <img class="img" :src="item.photoUrl" alt=""/>
        <div class="name">
          <span>{{item.name}}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PopularDestination',
    props: {
      list: Array
    }
  };
</script>
<style scoped lang="less">
  .center-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .hd{margin-top:60px;text-align:center;font-size:24px;}

  .hots{
    .item{
      display:block;position:relative;float:left;width:300px;height:200px;margin-top:30px;margin-right:15px;margin-left:15px;overflow:hidden;border-radius:4px;
    }
    .name{
      position:absolute;top:0;right:0;bottom:0;left:0;text-align:center;background-color:rgba(0, 0, 0, 10%);
      &:after{display:inline-block;height:100%;vertical-align:middle;content:'';}
      span{display:inline-block;vertical-align:middle;color:#fff;font-size:24px;font-weight: 500;}
    }
  }
</style>
