<template>
  <div class="sidebar">
    <!--基本信息-->
    <!--<div class="tree-node">
      <div class="content">
        <router-link :to="{name:'AdminMy'}" activeClass="active">{{$t('BasicInformation')}}</router-link>
      </div>
    </div>-->
    <!--產品管理-->
    <!--    <div class="tree-node" v-if="['root', 'admin'].includes(roleType)">-->
    <!--      <div class="content">-->
    <!--        <router-link :to="{name:'AdminProductList'}" activeClass="active">{{$t('ProductManagement')}}-->
    <!--        </router-link>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash;訂單管理&ndash;&gt;-->
    <!--    <div class="tree-node" :class="{current:currentTree==='order'}" v-if="['root', 'admin', 'customer'].includes(roleType)">-->
    <!--      <div class="content">-->
    <!--        <a href="javascript:;" @click="setCurrent('order')">{{$t('OrderManagement')}}</a>-->
    <!--      </div>-->
    <!--      <div class="group">-->
    <!--        <div class="tree-node">-->
    <!--          <div class="content">-->
    <!--            <router-link :to="{name:'AdminOrderList'}" activeClass="active" :exact="true">{{$t('AllOrders')}}-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="tree-node">-->
    <!--          <div class="content">-->
    <!--            <router-link :to="{name:'AdminCancelOrderList'}" activeClass="active" :exact="true">-->
    <!--              {{$t('Cancel order')}}-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="tree-node">-->
    <!--          <div class="content">-->
    <!--            <router-link :to="{name:'AdminOrderList',query:{type:'my'}}" activeClass="active" :exact="true">-->
    <!--              {{$t('MyOrder')}}-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="tree-node">-->
    <!--          <div class="content">-->
    <!--            <router-link :to="{name:'AdminAddOrder'}" activeClass="active" :exact="true">{{$t('NewManualOrder')}}-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash;報價&ndash;&gt;-->
    <!--    <div class="tree-node" v-if="['root', 'admin'].includes(roleType)">-->
    <!--      <div class="content">-->
    <!--        <router-link :to="{name:'AdminQuote'}" activeClass="active">{{$t('Quote')}}</router-link>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash;結算管理&ndash;&gt;-->
    <!--    <div class="tree-node" v-if="['root', 'finance'].includes(roleType)">-->
    <!--      <div class="content">-->
    <!--        <router-link :to="{name:'AdminSettlementList'}" activeClass="active">-->
    <!--          {{$t('SettlementManagement')}}-->
    <!--        </router-link>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash;數據報告&ndash;&gt;-->
    <!--    <div class="tree-node" v-if="['root', 'admin'].includes(roleType)">-->
    <!--      <div class="content">-->
    <!--        <router-link :to="{name:'AdminDataReport'}" activeClass="active" :exact="true">-->
    <!--          {{$t('DataReport')}}-->
    <!--        </router-link>-->
    <!--      </div>-->
    <!--    </div>-->
    <template v-for="item of newMenuList">
      <div class="tree-node" :class="{current:currentTree===item.prefix}" :key="item.id"
           v-if="item.link!=='/admin/message'">
        <div class="content" :class="{guide:guideClass(item)}">
          <a @click="setCurrent(item.prefix)" v-if="item.childrenList.length">
            {{item.menuName}}
          </a>
          <router-link :to="{path:item.link}" activeClass="active" v-else>{{item.menuName}}</router-link>
          <!--產品編輯、修改價格都在這裡-->
          <GuideItem type="admin"
                     current="2"
                     :on-next="()=>$store.dispatch('guide/setAdminGuide','3')"
                     :content="$t('tip1')"
                     key="adminProduct"
                     v-if="item.link==='/admin/product'"/>
          <!--確認/取消訂單、調團、匯出客戶資料等都在這裡-->
          <GuideItem type="admin"
                     current="3"
                     :on-next="()=>$store.dispatch('guide/setAdminGuide','4')"
                     :content="$t('tip2')"
                     key="adminOrder"
                     v-if="item.id==='c7298a96a1344f809b91486503cd3ce4'"/>
          <!--查看客戶包團需求、提交報價都在這裡-->
          <GuideItem type="admin"
                     current="4"
                     :on-next="()=>$store.dispatch('guide/setAdminGuide','5')"
                     :content="$t('tip3')"
                     key="adminQuote"
                     v-if="item.link==='/admin/quote'"/>
          <!--在這裡修改您的公司主頁簡介、聯絡方式、管理主頁廣告-->
          <GuideItem type="admin"
                     current="5"
                     :on-next="()=>$store.dispatch('guide/setAdminGuide','6')"
                     :content="$t('tip4')"
                     key="AdminInformation"
                     v-if="item.link==='/admin/information'"/>
          <!--在這裡開通賬戶給其他同事，達成團隊協作-->
          <GuideItem type="admin"
                     current="6"
                     :content="$t('tip5')"
                     key="adminSystem"
                     v-if="item.id==='461208ba1af64496a0189f89e59e6fe6'"/>
        </div>
        <div class="group">
          <div class="tree-node" v-for="sitem of item.childrenList" :key="sitem.id">
            <div class="content">
              <router-link :to="{path:sitem.link}" activeClass="active" :exact="true">
                {{sitem.menuName}}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!--前台管理-->
<!--    <div class="tree-node">-->
<!--      <div class="content">-->
<!--        <router-link :to="{name:'AdminInformation'}" activeClass="active">{{$t('FrontManagement')}}</router-link>-->
<!--      </div>-->
<!--    </div>-->
    <!--会员升级-->
<!--    <div class="tree-node">-->
<!--      <div class="content">-->
<!--        <router-link :to="{name:'AdminMember'}" activeClass="active">{{$t('MemberUpgrade')}}</router-link>-->
<!--      </div>-->
<!--    </div>-->
    <!--系統管理-->
    <!--    <div class="tree-node" :class="{current:currentTree==='system'}" v-if="['root'].includes(roleType)">-->
    <!--      <div class="content">-->
    <!--        <a href="javascript:;" @click="setCurrent('system')">{{$t('SystemManagement')}}</a>-->
    <!--      </div>-->
    <!--      <div class="group">-->
    <!--        &lt;!&ndash;角色管理&ndash;&gt;-->
    <!--        <div class="tree-node">-->
    <!--          <div class="content">-->
    <!--            <router-link :to="{name:'AdminRoleManagement'}" activeClass="active" :exact="true">{{$t('RoleManagement')}}-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        &lt;!&ndash;帳戶管理&ndash;&gt;-->
    <!--        <div class="tree-node">-->
    <!--          <div class="content">-->
    <!--            <router-link :to="{name:'AdminAccountManagement'}" activeClass="active" :exact="true">-->
    <!--              {{$t('AccountManagement')}}-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import GuideItem from '../GuideItem';

  export default {
    name: 'Sidebar',
    i18n: {
      messages: {
        'zh-cn': {
          tip1: '产品编辑、修改价格都在这里',
          tip2: '确认/取消订单、调团、汇出客户资料等都在这里',
          tip3: '查看客户包团需求、提交报价都在这里',
          tip4: '在这里修改您的公司主页简介、联络方式、管理主页广告',
          tip5: '在这里开通账户给其他同事，达成团队协作'
        },
        'zh-hk': {
          tip1: '產品編輯、修改價格都在這裡',
          tip2: '確認/取消訂單、調團、匯出客戶資料等都在這裡',
          tip3: '查看客戶包團需求、提交報價都在這裡',
          tip4: '在這裡修改您的公司主頁簡介、聯絡方式、管理主頁廣告',
          tip5: '在這裡開通賬戶給其他同事，達成團隊協作'
        },
        'en': {

          tip1: '產品編輯、修改價格都在這裡',
          tip2: '確認/取消訂單、調團、匯出客戶資料等都在這裡',
          tip3: '查看客戶包團需求、提交報價都在這裡',
          tip4: '在這裡修改您的公司主頁簡介、聯絡方式、管理主頁廣告',
          tip5: '在這裡開通賬戶給其他同事，達成團隊協作'
        }
      }
    },
    components: {GuideItem},
    data() {
      return {
        currentTree: ''
      };
    },
    computed: {
      ...mapGetters(['menuList', 'adminGuide']),
      newMenuList() {
        let menuList = JSON.parse(JSON.stringify(this.menuList));
        menuList = menuList.map(item => {
          if (item.childrenList.length) {
            item.prefix = (item.childrenList[0].link || '').split('/')[2] || '';
          }
          return item;
        });
        return menuList;
      },
      activeMenu() {
        const route = this.$route;
        const {meta, name} = route;
        if (meta.activeMenu) {
          return meta.activeMenu;
        }
        return name;
      }
    },
    created() {
      this.currentTree = this.$route.path.split('/')[2] || '';
    },
    mounted() {
      const adminGuide = window.localStorage.getItem('AdminGuide');
      const roleType = window.localStorage.getItem('roleType');
      if (!adminGuide && roleType === 'root') {
        this.$store.dispatch('guide/setAdminGuide', '1');
      }
    },
    methods: {
      setCurrent(name) {
        this.currentTree = name === this.currentTree ? '' : name;
      },
      guideClass(item) {
        return (item.link === '/admin/product' && this.adminGuide === '2') || (item.id === 'c7298a96a1344f809b91486503cd3ce4' && this.adminGuide === '3') || (item.link === '/admin/quote' && this.adminGuide === '4') || (item.link === '/admin/information' && this.adminGuide === '5') || (item.id === '461208ba1af64496a0189f89e59e6fe6' && this.adminGuide === '6');
      }
    },
    watch: {
      $route: function (value) {
        this.currentTree = value.path.split('/')[2] || '';
      }
    }
  };
</script>
<style scoped lang="less">
  .sidebar{
    .tree-node{
      a{
        display:block;line-height:68px;text-align:center;color:#321908;font-size:20px;border-bottom:1px dashed #e1e0df;
        &:hover,
        &.active{color:#ff6f61;}
      }
    }
    .group{display:none;}
    .current{
      .group{display:block;background-color:#fff;}
    }
  }
</style>
