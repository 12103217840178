<template>
  <div class="classification cl center-box" v-if="list&&list.length">
    <router-link class="item"
                 v-for="item of list"
                 :style="{backgroundImage:`url(${item.photoUrl})`}"
                 :to="{name:'ThemeTravel',query:{classification:item.businessId}}"
                 :key="item.id">
      {{item.name}}
    </router-link>
  </div>
</template>
<script>
  export default {
    name: 'Classification',
    props: {
      list: Array
    }
  };
</script>
<style scoped lang="less">
  .center-box{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .classification{
    padding:60px 0 30px;
    .item{
      width:168px;height:360px;margin-right:15px; margin-left:15px;margin-top: 30px; padding-top:64px;text-align:center;color:#fff;font-size:24px;background-position:center center;background-size:cover;
    }
    @media only screen and (max-width: 768px) {
    .item{
    height:180px;
    }
   }
  }
</style>
