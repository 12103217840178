const state = {
  userGuide: '',
  adminGuide: ''
};

const mutations = {
  SET_USER_GUIDE: (state, userGuide) => {
    state.userGuide = userGuide;
  },
  SET_ADMIN_GUIDE: (state, adminGuide) => {
    state.adminGuide = adminGuide;
  }
};

const actions = {
  setUserGuide({commit, state}, userGuide) {
    commit('SET_USER_GUIDE', userGuide);
  },
  setAdminGuide({commit, state}, adminGuide) {
    commit('SET_ADMIN_GUIDE', adminGuide);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
