import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 获取角色列表（分页）
export function getAdminRoleList(data) {
  return request({
    url: '/admin/role/list',
    baseURL: '/v2',
    method: 'get',
    params: data
  });
}

// 获取角色列表（不分页）
export function getAdminAllRoleList() {
  return request({
    url: '/admin/role/admin/list',
    baseURL: '/v2',
    method: 'get'
  });
}

// 获取角色详情
export function getAdminRoleDetail(roleId) {
  return request({
    url: `/admin/role/details/${language}`,
    baseURL: '/v2',
    method: 'get',
    params: {roleId}
  });
}

// 保存角色
export function saveAdminRole(data) {
  return request({
    url: '/admin/role/save',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 删除角色
export function deleteAdminRole(roleId) {
  return request({
    url: `/admin/role/delete/${roleId}`,
    baseURL: '/v2',
    method: 'post'
  });
}

// 获取拥有该角色的用户数量
export function getCount(roleId) {
  return request({
    url: `/admin/role/count/${roleId}`,
    baseURL: '/v2',
    method: 'get'
  });
}
