/**
 * 手机码码验证
 * */
export function isPhone(phone) {
  const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
  return reg.test(phone);
};

// 邮箱验证
export function isEmail(email) {
  const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  return reg.test(email);
}

/**
 * 密码必须包含数字和字母
 * */
export function checkPassWord(password) {
  if (!password || password.length < 6) {
    return false;
  }

  const reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
  if (reg.test(password)) {
    return true;
  }
};
