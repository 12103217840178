import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 获取消息列表
export function getMessageList(data, status) {
  return request({
    url: `/admin/message/list/${language}/${status}`,
    baseURL: '/v2',
    method: 'get',
    params: data
  });
}

// 消息置顶/已读操作
export function setMessage(messageId, operateType = 'top') {
  return request({
    url: `/admin/message/operate/${messageId}/${operateType}`,
    baseURL: '/v2',
    method: 'post'
  });
}

// 删除消息
export function deleteMessage(messageId) {
  return request({
    url: `/admin/message/delete/${messageId}`,
    baseURL: '/v2',
    method: 'post'
  });
}
// 获取站内消息列表
export function getNoticeMessageList(data) {
  return request({
    url: `/admin/noticeMessage/list`,
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 获取站内消息未读数量
export function getNoticeMessageCount(data) {
  return request({
    url: `/admin/noticeMessage/count`,
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 更改站内消息
export function updateNoticeMessage(data) {
  return request({
    url: `/admin/noticeMessage/update`,
    baseURL: '/v2',
    method: 'post',
    data
  });
}
