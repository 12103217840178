import {Basic, Admin, AdminPage} from '../layouts';

export default [
  {
    path: '/admin',
    component: Admin,
    children: [
      {
        path: 'login',
        name: 'AdminLogin',
        component: () => import('../views/admin/account/login')
      },
      {
        path: 'registered',
        name: 'AdminRegistered',
        component: () => import('../views/admin/account/registered')
      },
      {
        path: '',
        component: AdminPage,
        redirect: {name: 'AdminLogin'},
        children: [
          {
            path: 'my',
            name: 'AdminMy',
            component: () => import('../views/admin/my')
          },
          {
            path: 'quote',
            name: 'AdminQuote',
            component: () => import('../views/admin/quote'),
            meta: {roles: ['/admin/quote']}
          },
          {
            path: 'quote/detail/:id',
            name: 'AdminQuoteDetail',
            component: () => import('../views/admin/quote/detail'),
            meta: {roles: ['/admin/quote']}
          },
          {
            path: 'product',
            name: 'AdminProductList',
            component: () => import('../views/admin/product/list'),
            meta: {roles: ['/admin/product']}
          },
          {
            path: 'product/add',
            name: 'AdminProductAdd',
            component: () => import('../views/admin/product/add'),
            meta: {roles: ['/admin/product']}
          },
          {
            path: 'product/select',
            name: 'HotProductSelect',
            component: () => import('../views/admin/product/hotProductSelect'),
            meta: {roles: ['/admin/product']}
          },
          {
            path: 'order',
            name: 'AdminOrderList',
            component: () => import('../views/admin/order/list'),
            meta: {roles: ['/admin/order']}
          },
          {
            path: 'order/list/tourists',
            name: 'AdminOrderListTourists',
            component: () => import('../views/admin/order/listTourists'),
            meta: {roles: ['/admin/order', '/admin/order/cancel', '/admin/order?type=my']}
          },
          {
            path: 'order/list/:id',
            name: 'AdminOrderListDetail',
            component: () => import('../views/admin/order/listDetail'),
            meta: {roles: ['/admin/order', '/admin/order/cancel', '/admin/order?type=my']}
          },
          {
            path: 'order/detail',
            name: 'AdminOrderDetail',
            component: () => import('../views/admin/order/detail'),
            meta: {roles: ['/admin/order', '/admin/order/cancel', '/admin/order?type=my']}
          },
          {
            path: 'order/groupNumberDetail',
            name: 'AdminOrderGroupNumberDetail',
            component: () => import('../views/admin/order/groupNumberDetail'),
            meta: {roles: ['/admin/order', '/admin/order/cancel', '/admin/order?type=my']}
          },
          {
            path: 'order/add',
            name: 'AdminAddOrder',
            component: () => import('../views/admin/order/add'),
            meta: {roles: ['/admin/order/add']}
          },
          {
            path: 'order/changeGroup',
            name: 'AdminChangeGroup',
            component: () => import('../views/admin/order/changeGroup'),
            meta: {roles: ['/admin/order', '/admin/order/cancel', '/admin/order?type=my']}
          },
          {
            path: 'order/cancel',
            name: 'AdminCancelOrderList',
            component: () => import('../views/admin/cancel-order/list'),
            meta: {roles: ['/admin/order/cancel']}
          },
          {
            path: 'order/cancel/detail',
            name: 'AdminCancelOrderDetail',
            component: () => import('../views/admin/cancel-order/detail'),
            meta: {roles: ['/admin/order/cancel']}
          },
          {
            path: 'order/cancel/application',
            name: 'AdminCancelOrderApplication',
            component: () => import('../views/admin/cancel-order/application'),
            meta: {roles: ['/admin/order/cancel']}
          },
          {
            path: 'information',
            name: 'AdminInformation',
            component: () => import('../views/admin/information/list')
          },
          {
            path: 'information/edit',
            name: 'AdminInformationEdit',
            component: () => import('../views/admin/information/edit')
          },
          {
            path: 'data/report',
            name: 'AdminDataReport',
            component: () => import('../views/admin/data/report'),
            meta: {roles: ['/admin/data/report']}
          },
          {
            path: 'system/role',
            name: 'AdminRoleManagement',
            component: () => import('../views/admin/system/role'),
            meta: {roles: ['/admin/system/role']}
          },
          {
            path: 'system/account',
            name: 'AdminAccountManagement',
            component: () => import('../views/admin/account/management'),
            meta: {roles: ['/admin/system/account']}
          },
          {
            path: 'settlement',
            name: 'AdminSettlementList',
            component: () => import('../views/admin/settlement/list'),
            meta: {roles: ['/admin/settlement']}
          },
          {
            path: 'settlement/detail/:id',
            name: 'AdminSettlementDetail',
            component: () => import('../views/admin/settlement/detail'),
            meta: {roles: ['/admin/settlement']}
          },
          {
            path: 'message',
            name: 'AdminMessage',
            component: () => import('../views/admin/message'),
            meta: {roles: ['/admin/message']}
          },
          {
            path: 'member',
            name: 'AdminMember',
            component: () => import('../views/admin/member')
          },
          {
            path: 'changePassword',
            name: 'AdminChangePassword',
            component: () => import('../views/admin/account/changePassword')
          },
          {
            path: 'teaching',
            name: 'TeachingVido',
            component: () => import('../views/admin/teaching')
          }
        ],
        meta: {requiresAuth: true}
      }
    ]
  },
  {
    path: '/business',
    component: Basic,
    children: [
      {
        path: '',
        name: 'AdminBusinessList',
        component: () => import('../views/admin/business/list')
      },
      {
        path: 'detail/:id',
        name: 'AdminBusinessDetail',
        component: () => import('../views/admin/business/detail')
      },
      {
        path: 'news/:id',
        name: 'AdminBusinessNewsDetail',
        component: () => import('../views/admin/business/newsDetail')
      }
    ]
  }
];
