import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 上传附件
export function upload(data, onUploadProgress) {
  return request({
    url: '/common/file',
    method: 'post',
    headers: {'Content-Type': 'multipart/form-data'},
    // 添加上传进度监听事件
    onUploadProgress,
    data
  });
}

// 获取数据字典列表
export function getDictList(domain) {
  return request({
    url: `/common/dict/list/${domain}/${language}`,
    method: 'get'
  });
}

// 获取所有城市字典列表
export function getAllCityList() {
  return request({
    url: `/common/all/city/list/${language}`,
    method: 'get'
  });
}

// 获取货币种类
export function getCurrencyList() {
  return request({
    url: `/convert/list/${language}`,
    method: 'get'
  });
}

// 提交联络信息/最新资讯
export function addContact(data) {
  return request({
    url: '/contact/add',
    method: 'post',
    data
  });
}
