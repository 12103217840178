import axios from 'axios';
import {Message} from 'element-ui';
import router from '../router';
import {getToken, removeToken} from '@/utils/auth';

// create an axios instance
const service = axios.create({
  baseURL: '/v1/api',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});

// Add a request interceptor
service.interceptors.request.use((config) => {
  const token = getToken(config.baseURL.includes('/v2') ? 'adminToken' : undefined);
  config.headers['token'] = token || null;
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Add a response interceptor
service.interceptors.response.use((response) => {
  const res = response.data;
  if (res.code !== 200) {
    if ([30003].includes(res.code)) {
      const userType = response.config.baseURL.includes('/v2') ? 'admin' : 'user';
      removeToken(userType === 'admin' ? 'adminToken' : undefined);
      router.replace({
        name: userType === 'admin' ? 'AdminLogin' : 'Login',
        query: {redirect: router.history.current.fullPath, invalidToken: '1'}
      });
    } else if ([10030].includes(res.code)) {
      Message.error(res.message);
      history.back();
    } else {
      // 除了token失效之外的错误信息都提示
      Message.error(res.message);
    }

    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, (error) => {
  Message.error(error.message);
  return Promise.reject(error);
});

window.ajax = service;

export default service;
