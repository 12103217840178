import {Basic, User} from '../layouts';

export default [
  {
    path: '/user',
    component: User,
    redirect: {name: 'My'},
    children: [
      {
        path: 'my',
        name: 'My',
        component: () => import(/* webpackChunkName: "my" */ '../views/my')
      },
      {
        path: 'my/profile',
        name: 'MyProfile',
        component: () => import(/* webpackChunkName: "myprofile" */ '../views/my/myProfile')
      },
      {
        path: 'my/credit',
        name: 'MyCredit',
        component: () => import(/* webpackChunkName: "myprofile" */ '../views/credit/index')
      },
      {
        path: 'quote',
        name: 'UserQuote',
        component: () => import(/* webpackChunkName: "user-quote" */ '../views/user/quote')
      },
      {
        path: 'quote/edit',
        name: 'UserQuoteEdit',
        component: () => import(/* webpackChunkName: "user-quote-edit" */ '../views/user/quote/edit')
      },
      {
        path: 'quote/detail/:id',
        name: 'UserQuoteDetail',
        component: () => import(/* webpackChunkName: "user-quote" */ '../views/user/quote/detail')
      },
      {
        path: 'competition',
        name: 'MyCompetition',
        component: () => import(/* webpackChunkName: "mycompetition" */ '../views/competition/my')
      },
      {
        path: 'competition/add',
        name: 'CompetitionProductAdd',
        component: () => import(/* webpackChunkName: "CompetitionProductAdd" */ '../views/competition/add')
      },
      {
        path: 'order',
        name: 'OrderList',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/list')
      },
      {
        path: 'invoice',
        name: 'InvoiceList',
        component: () => import(/* webpackChunkName: "order" */ '../views/invoice/list')
      },
      {
        path: 'order/comment',
        name: 'OrderComment',
        component: () => import(/* webpackChunkName: "ordercomment" */ '../views/order/comment')
      },
      {
        path: 'order/comment/detail/:id',
        name: 'OrderCommentDetail',
        component: () => import(/* webpackChunkName: "ordercomment" */ '../views/order/commentDetail')
      },
      {
        path: 'collection',
        name: 'MyCollection',
        component: () => import(/* webpackChunkName: "mycollection" */ '../views/collection/my')
      },
      {
        path: 'message',
        name: 'MessageList',
        component: () => import(/* webpackChunkName: "message" */ '../views/message/list')
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/contact')
      },
      {
        path: 'travels',
        name: 'MyTravels',
        component: () => import(/* webpackChunkName: "mytravels" */ '../views/travels/my'),
        meta: {roles: ['travels']}
      },
      {
        path: 'travels/edit',
        name: 'EditTravels',
        component: () => import(/* webpackChunkName: "mytravels-edit" */ '../views/travels/edit'),
        meta: {roles: ['travels']}
      }
    ],
    meta: {requiresAuth: true}
  },
  {
    path: '/user',
    component: Basic,
    children: [
      {
        path: 'order/detail/:id',
        name: 'OrderDetail',
        component: () => import(/* webpackChunkName: "order-detail" */ '../views/order/detail'),
        meta: {requiresAuth: true}
      },
      {
        path: 'order/confirm',
        name: 'ConfirmOrder',
        component: () => import(/* webpackChunkName: "confirmorder" */ '../views/order/confirm'),
        meta: {requiresAuth: true}
      }
    ]
  }
];
