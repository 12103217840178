import request from '@/utils/request';

// 获取验证码
export function getCaptcha(data) {
  return request({
    url: '/user/captcha',
    method: 'post',
    data
  });
}

// 注册
export function register(data) {
  return request({
    url: '/user/register',
    method: 'post',
    data
  });
}

// 登录
export function login(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  });
}

// 微信登录
export function render(data) {
  return request({
    url: '/user/wechatCallback',
    method: 'post',
    data
  });
}

// 登出
export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  });
}

// 找回密码，重置密码
export function resetPassword(data) {
  return request({
    url: '/user/forget',
    method: 'post',
    data
  });
}

// 修改密码
export function updatePassword(data) {
  return request({
    url: '/user/update/password',
    method: 'post',
    data
  });
}

// 获取我的信息
export function getMyInfo() {
  return request({
    url: '/user/my/info',
    method: 'get'
  });
}

// Get User's Credit
export function getMyCredit() {
  return request({
    url: '/user/my/credit',
    method: 'get'
  });
}

// 修改我的信息
export function updateMyInfo(data) {
  return request({
    url: '/user/update/my/info',
    method: 'post',
    data
  });
}

// 修改头像
export function updateAvatar(data) {
  return request({
    url: '/user/update/photo',
    method: 'post',
    data
  });
}

// 获取联络人信息
export function getGroupContactInfo() {
  return request({
    url: '/user/group/contact',
    method: 'get'
  });
}

// 修改联络人信息
export function updateGroupContactInfo(data) {
  return request({
    url: '/user/update/group/contact',
    method: 'post',
    data
  });
}
