<template>
  <el-container id="app">
    <el-header><page-header/></el-header>
    <el-container>
      <el-aside width="200px" class="sidebar"><sidebar/></el-aside>
      <el-main class="main-columns layout cl">
        <router-view />

        <el-backtop>
          <i class="el-icon-caret-top"></i>
        </el-backtop>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
  import PageHeader from '../components/PageHeader';
  import Sidebar from '../components/Sidebar';

  export default {
    name: 'User',
    components: {PageHeader, Sidebar}
  };
</script>
<style scoped>
  .main-columns{margin-top:30px;}
  .main-columns >>> > .main .h1{font-size:24px;}
  @media only screen and (max-width: 768px) {
    .sidebar {
      display: none;
    }
    .page-class{
      display: none;
    }
  }

</style>
