import {scrollToY} from '../utils';

export default {
  data() {
    return {
      pageY: 0
    };
  },
  beforeRouteLeave(to, from, next) {
    this.pageY = document.getElementById('app').scrollTop;
    // document.querySelector('#app').scrollTo(0, 0);
    next();
  },
  activated() {
    scrollToY(undefined, this.pageY);
    if (this.pageY) {
      setTimeout(() => {
        scrollToY(undefined, this.pageY);
      }, 0);
    }
  }
};
