import request from '@/utils/request';
import {getLocale} from '../utils/language';
import {getCurrency} from '../utils';

const language = getLocale() || 'zh-hk';
const currency = getCurrency();

// 获取收藏列表
export function getCollectionList(data) {
  data.currencyCode = currency;
  return request({
    url: `/collection/list/${language}`,
    method: 'get',
    params: data
  });
}

// 收藏产品
export function productCollection(id) {
  return request({
    url: `/collection/business/product/${id}`,
    method: 'post'
  });
}

// 收藏游记
export function travelsCollection(id) {
  return request({
    url: `/collection/business/travels/${id}`,
    method: 'post'
  });
}

// 取消收藏
export function cancelCollection(id) {
  return request({
    url: `/collection/cancel/business/${id}`,
    method: 'post'
  });
}
