import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementLocale from 'element-ui/lib/locale';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhCnLocale from 'element-ui/lib/locale/lang/zh-CN';
import zhHkLocale from 'element-ui/lib/locale/lang/zh-TW';
import {getLocale} from './utils/language';

const locale = getLocale();

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  const elLangs = {en: enLocale, 'zh-cn': zhCnLocale, 'zh-hk': zhHkLocale};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = Object.assign(locales(key), (elLangs[locale] || {}));
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: locale || process.env.VUE_APP_I18N_LOCALE || 'zh-hk',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh-hk',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n;
