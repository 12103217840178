<template>
  <div class="copyright">
    <div class="cl">
      <div class="fl">Copyright © {{year}} UNU TECHNOLOGY LIMITED. All rights reserved.</div>
      <div class="fr">
        <span>(852) 2616 0676</span>
        <span>info@unutravel.com</span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Copyright',
    data() {
      return {
        year: new Date().getFullYear()
      };
    }
  };
</script>
<style scoped lang="less">
  .copyright{
    padding:0 32px;line-height:60px;color:#fff;background-color:#fe6f60;background-image:linear-gradient(45deg, #fad7a1 0%, #fe6f60 100%);
    span{display:inline-block;margin-left:30px;}
  }
</style>
