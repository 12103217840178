<template>
  <div class="avatar">
    <img class="img" :src="avatarUrl||require('../assets/images/avatar.jpg')"/>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';

  export default {
    name: 'Avatar',
    props: {
      url: {
        type: String,
        default: ''
      },
      isSelf: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters(['avatar', 'adminAvatar']),
      avatarUrl: function () {
        if (this.isSelf) {
          return this.url || (this.$route.fullPath.includes('/admin') ? this.adminAvatar : this.avatar);
        } else {
          return this.url;
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .avatar{width:80px;height:80px;overflow:hidden;border-radius:50%;}
</style>
