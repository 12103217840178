<template>
  <div class="merchant">
    <div class="hd">
      <h3 class="h3">{{$t('StarMerchant')}}</h3>
    </div>
    <div class="bd">
      <swiper :options="swiperOption">
        <swiper-slide class="item" v-for="item of list" :key="item.businessId">
          <router-link target="_blank" :to="{name: 'AdminBusinessDetail', params: {id:item.userId}}">
            <div class="cover">
              <img class="img" :src="item.photoUrl"/>
            </div>
            <div class="title">{{item.businessName}}</div>
          </router-link>
        </swiper-slide>
        <div id="btnPrevStarMerchant" class="btn-prev" slot="button-prev">
          <img src="../../../assets/images/btn-prev.png" alt="上一頁"/>
        </div>
        <div id="btnNextStarMerchant" class="btn-next" slot="button-next">
          <img src="../../../assets/images/btn-next.png" alt="下一頁"/>
        </div>
      </swiper>
    </div>
    <div class="ft">
      <router-link class="btn-more" target="_blank" :to="{name:'AdminBusinessList'}">
        {{$t('All merchants')}}
      </router-link>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'StarMerchant',
    props: {
      list: Array
    },
    data() {
      return {
        swiperOption: {
          slidesPerView: 'auto',
          spaceBetween: 60,
          slidesPerGroup: 5,
          navigation: {
            prevEl: '#btnPrevStarMerchant',
            nextEl: '#btnNextStarMerchant'
          }
        }
      };
    }
  };
</script>
<style scoped lang="less">
  .merchant{
    margin-top:60px;
    .hd{position:relative;}
    .bd{margin-top:60px;justify-content: center;display: flex;}
    .ft{margin-top:30px;text-align:center;}
    .h3{text-align:center;font-size:24px;}
    .btn-more{display:inline-block;padding:9px 22px;vertical-align:top;color:#ff6f61;font-size:16px;border:1px solid #ff6f61;border-radius:4px;}
    .item{width:120px;cursor:pointer;}
    .cover{height:120px;overflow:hidden;border:4px solid #f9d7a0;border-radius:50%;}
    .title{min-height:21px;margin-top:20px;text-align:center;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
    .btn-prev,
    .btn-next{
      position:absolute;top:0;bottom:0;left:0;z-index:2;width:60px;padding-top:48px;cursor:pointer;
      &.swiper-button-disabled{display:none;}
    }
    .btn-prev{text-align:left;background-image:linear-gradient(to left, rgba(255, 255, 255, 0.6) 0%, #fff 100%);}
    .btn-next{left:auto;right:0;text-align:right;background-image:linear-gradient(to right, rgba(255, 255, 255, 0.6) 0%, #fff 100%);}
  }
</style>
