import {Basic} from '../layouts';
import Home from '../views/home/index';

export default [
  {
    path: '/',
    component: Basic,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'competition',
        name: 'competition',
        component: () => import('../views/competition')
      },
      {
        path: 'competition/detail/9876c08e407743938cce92a255d9c545',
        name: 'competitionDetailDirect',
        component: () => import('../views/competition/detail')
      },
      {
        path: 'competition/detail/:id?',
        name: 'competitionDetail',
        component: () => import('../views/competition/detail')
      },
      // {
      //   path: 'competition/pList/:id?',
      //   name: 'competitionProductList',
      //   component: () => import('../views/competition/list')
      // },
      // {
      //   path: 'competition/productDetail/:id?',
      //   name: 'competitionProductDetail',
      //   component: () => import('../views/competition/productDetail')
      // },
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/account/login')
      },
      {
        path: 'registered',
        name: 'Registered',
        component: () => import('../views/account/registered')
      },
      {
        path: 'registeredGroup',
        name: 'RegisteredGroup',
        component: () => import('../views/account/registeredGroup')
      },
      {
        path: 'forgetPassword',
        name: 'ForgetPassword',
        component: () => import('../views/account/forgetPassword')
      },
      {
        path: 'featured',
        name: 'Featured',
        component: () => import('../views/featured')
      },
      {
        path: 'featured/detail',
        name: 'FeaturedDetail',
        component: () => import('../views/featured/detail')
      },
      {
        path: 'themetravel',
        name: 'ThemeTravel',
        component: () => import('../views/themetravel')
      },
      {
        path: 'travels',
        name: 'Travels',
        component: () => import('../views/travels')
      },
      {
        path: 'travels/detail/:id',
        name: 'TravelsDetail',
        component: () => import('../views/travels/detail')
      },
      {
        path: 'travels/detail/:id/keyword',
        name: 'TravelsKeyword',
        component: () => import('../views/travels/keyword')
      },
      {
        path: 'travels/traveler',
        name: 'TravelerList',
        component: () => import('../views/travels/traveler-list')
      },
      {
        path: 'travels/traveler/:id',
        name: 'TravelerDetail',
        component: () => import('../views/travels/traveler-detail')
      },
      {
        path: 'tourism/detail/:id',
        name: 'TourismDetail',
        component: () => import('../views/tourism/detail')
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import('../views/search')
      },
      {
        path: 'contactus',
        name: 'ContactUs',
        component: () => import('../views/contactUs.vue')
      }, {
        path: 'scanner',
        name: 'scanner',
        component: () => import('../views/qrScanner/qrScanner.vue')
      },
      {
        path: 'promotions/:id',
        name: 'PromotionsDetail',
        component: () => import('../views/home/promotionsDetail')
      },
      {
        path: 'about/:type',
        name: 'About',
        component: () => import('../views/about')
      },
      {
        path: 'banner/detail/:id',
        name: 'BannerDetail',
        component: () => import('../views/banner/detail')
      },
      {
        path: '/webapp/joinus',
        name: 'Joinus',
        component: () => import('../views/webapp/joinus')
      },
      {
        path: '/webapp/joinus/detail',
        name: 'JoinusDetail',
        component: () => import('../views/webapp/joinusDetail')
      }
    ]
  }
];
