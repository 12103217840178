<template>
  <div class="star">
    <div class="filling" :style="{width:value}"></div>
  </div>
</template>
<script>
  export default {
    name: 'Star',
    props: {
      value: {
        type: String
      }
    }
  };
</script>
<style scoped lang="less">
  .star{width:120px;height:16px;background-image:url('../assets/images/icon/star.png');}
  .filling{width:100%;height:100%;background-image:url('../assets/images/icon/star-full.png');}
</style>
