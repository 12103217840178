import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import adminUser from './modules/admin-user';
import guide from './modules/guide';
import getters from './getters';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    adminUser,
    guide
  },
  getters
});
