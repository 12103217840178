import Cookies from 'js-cookie';

export function getToken(TokenKey = 'token') {
  return Cookies.get(TokenKey);
}

export function setToken(token, TokenKey = 'token') {
  return Cookies.set(TokenKey, token, {expires: 31});
}

export function removeToken(TokenKey = 'token') {
  return Cookies.remove(TokenKey);
}

export function getUserId() {
  return Cookies.get('userId');
}

export function setUserId(userId) {
  return Cookies.set('userId', userId, {expires: 31});
}

export function removeUserId() {
  return Cookies.remove('userId');
}
