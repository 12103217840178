import {dicts} from '../config';
import {formatDate} from '../utils';
import i18n from '../i18n';

export {formatDate, numberToLetter, currencySymbol} from '@/utils';

/**
 * 过滤器
 */

/**
 * 报价状态
 * */
export function quoteStatus(status) {
  const statusObj = {edit: '未發布', issue: '進行中', over: '已結束', end: '已終止'};
  return statusObj[status] || status;
}

/**
 * 产品状态
 * */
export function productStatus(status) {
  const statusObj = {unpublished: '未發布', published: '已發布', obtained: '下架'};
  return statusObj[status] || status;
}

/**
 * 是否可用
 * */
export function availableStatus(status) {
  const statusObj = {Y: '可用', N: '不可用'};
  return statusObj[status] || status;
}

/**
 * 评分转换百分比
 * */
export function starToPercentage(starNumber) {
  return `${parseInt((starNumber) / 5 * 100)}%`;
}

/**
 * 数字转换(未完成)
 * */
export function interceptNumber(number) {
  return number;
}

/**
 * 去掉所有Html标签
 * */
export function delHtmlTag(html) {
  return html.replace(/<[^>]+>/g, '');
}

/**
 * 计算航班日期
 * */
export function flightDate(departureDate, fewDay) {
  if (departureDate && fewDay) {
    let timestamp = new Date(departureDate.replace(/-/g, '/')).getTime();
    timestamp += (fewDay - 1) * 24 * 60 * 60 * 1000;
    return formatDate(timestamp, 'MM-dd');
  } else {
    return '';
  }
}

/**
 * 字典名称
 * */
export function dictName(code, options, nameKey = 'name', codeKey = 'code') {
  if (!options) {
    return code;
  }
  if (!code || code === 'undefined' || code === 'null') {
    return '';
  }
  if (typeof options === 'string') {
    return (dicts[options] || {})[code] || code;
  } else {
    return (options.find(item => item[codeKey] === code) || {})[nameKey] || code;
  }
}

/**
 * 电话号码国号
 * */
export function phonePrefixer(str) {
  if (!str) {
    return '';
  } else {
    return str === 'N/A' ? '' : `+${str}`;
  }
}

/**
 * 交易状态
 * */
export function tradingStatus(orderStatus) {
  if (['NOT_SIGN_UP_SUCCESS', 'NOT_GROUP', 'YES_GROUP', 'TRAVELING', 'NOT_COMMENT', 'FINISH'].includes(orderStatus)) {
    return i18n.t('TradingNormal');
  } else if (['CANCEL', 'USER_CHECK_REFUND', 'REFUNDING', 'MISSION', 'CLOSE_TRADE'].includes(orderStatus)) {
    return i18n.t('TradingClosed');
  } else if (['BUSINESS_CHECK_REFUND', 'REFUSE_REFUND'].includes(orderStatus)) {
    return i18n.t('PlatformIntervention');
  }
  return i18n.t('AbnormalOrder');
}
// 真正的交易狀態
export function transationStatus(transationStatus) {
  return i18n.t('TransationStatus')[transationStatus];
}

// 訂單狀態
export function orderStatus(orderStatus) {
  return i18n.t('OrderStatus')[orderStatus];
}

// 結算狀態
export function settlementStatus(settlementStatus) {
  return i18n.t('SettlementStatus')[settlementStatus];
}

// 對帳狀態
export function reconciliationStatus(reconciliationStatus) {
  return i18n.t('ReconciliationStatus')[reconciliationStatus];
}

export function numberFormat(number, decimals = 2, decPoint = '.', thousandsSep = ',', roundtag) {
  /*
  * 参数说明：
  * number：要格式化的数字
  * decimals：保留几位小数
  * decPoint：小数点符号
  * thousandsSep：千分位符号
  * roundtag:舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
  * */
  number = (number + '').replace(/[^0-9+-Ee.]/g, '');
  roundtag = roundtag || 'ceil'; // "ceil","floor","round"
  var n = !isFinite(+number) ? 0 : +number;
    var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
    var dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
    var s = '';
    var toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + parseFloat(Math[roundtag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k;
    };
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  var re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2');
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}
