<template>
  <div class="sidebar">
    <!--我的主頁-->
    <div class="item">
      <router-link :to="{name:'My'}" activeClass="active" :exact="true">
        <i class="icons i-home"></i>
        <span class="label">{{$t('MyHomePage')}}</span>
      </router-link>
    </div>
    <!--個人資料-->
    <div class="item">
      <router-link :to="{name:'MyProfile'}" activeClass="active" :exact="true">
        <i class="icons i-account"></i>
        <span class="label">{{userType==='group'?$t('AccountInformation'):$t('personalInformation')}}</span>
      </router-link>
      <!--編輯個人資料、修改密碼、綁定賬戶、新增常用旅客，都在這裡-->
      <GuideItem current="1"
                 :on-next="()=>$store.dispatch('guide/setUserGuide','2')"
                 :content="$t('tip1')"/>
    </div>
    <!--我的積分-->
    <div class="item">
      <router-link :to="{name:'MyCredit'}" activeClass="active" :exact="true">
        <i class="icons i-coins"></i>
        <span class="label">{{$t('MyCredit')}}</span>
      </router-link>
    </div>
    <!--我的比賽-->
    <div class="item">
      <router-link :to="{name:'MyCompetition'}" activeClass="active" :exact="true">
        <i class="icons i-trophy"></i>
        <span class="label">{{$t('MyCompetition')}}</span>
      </router-link>
    </div>
    <!--報價邀約-->
    <div class="item" :class="{guide:userGuide==='2'}">
      <router-link :to="{name:'UserQuote'}" activeClass="active" :exact="false">
        <i class="icons i-group"></i>
        <span class="label">{{$t('GroupQuote')}}</span>
      </router-link>
      <!--想要獨一無二的定制包團服務嗎？可在這裡要求報價-->
      <GuideItem current="2"
                 :on-next="()=>$store.dispatch('guide/setUserGuide','3')"
                 :content="$t('tip2')"/>
    </div>
    <!--我的訂單-->
    <div class="item">
      <router-link :to="{name:'OrderList'}" activeClass="active" :exact="true">
        <i class="icons i-order"></i>
        <span class="label">{{$t('MyOrder')}}</span>
      </router-link>
    </div>
    <!--我的付款單-->
    <div class="item">
      <router-link :to="{name:'InvoiceList'}" activeClass="active" :exact="true">
        <i class="icons i-tag"></i>
        <span class="label">{{$t('MyInvoice')}}</span>
      </router-link>
    </div>
    <!--我的收藏-->
    <div class="item" :class="{guide:userGuide==='3'}">
      <router-link :to="{name:'MyCollection'}" activeClass="active" :exact="true">
        <i class="icons i-star"></i>
        <span class="label">{{$t('MyCollection')}}</span>
      </router-link>
      <!--還要再想想？沒有關係，你喜歡的都在這裡等你-->
      <GuideItem current="3" :content="$t('tip3')"/>
    </div>
    <!--我的評價-->
    <div class="item">
      <router-link :to="{name:'OrderComment'}" activeClass="active" :exact="true">
        <i class="icons i-evaluation"></i>
        <span class="label">{{$t('MyComment')}}</span>
      </router-link>
    </div>
    <!--消息-->
    <div class="item">
      <router-link :to="{name:'MessageList'}" activeClass="active" :exact="true">
        <i class="icons i-bell"></i>
        <span class="label">{{$t('Message')}}</span>
      </router-link>
    </div>
    <!--客服支援-->
<!--     <div class="item">
      <router-link :to="{name:'Contact'}" activeClass="active" :exact="true">
        <i class="icons i-contact"></i>
        <span class="label">{{$t('CustomerSupport')}}</span>
      </router-link>
    </div> -->
    <div class="item" v-if="isTravels==='Y'">
      <router-link :to="{name:'MyTravels'}" activeClass="active" :exact="true">
        <i class="icons i-location"></i>
        <span class="label">{{$t('MyTravels')}}</span>
      </router-link>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import GuideItem from './GuideItem';
  import {getNoticeMessageCount} from '@/api/message';

  export default {
    name: 'Sidebar',
    i18n: {
      messages: {
        'zh-cn': {
          tip1: '编辑个人资料、修改密码、绑定账户、新增常用旅客，都在这里',
          tip2: '想要独一无二的定制包团服务吗？可在这里要求报价',
          tip3: '还要再想想？没有关系，你喜欢的都在这里等你'
        },
        'zh-hk': {
          tip1: '編輯個人資料、修改密碼、綁定賬戶、新增常用旅客，都在這裡',
          tip2: '想要獨一無二的定制包團服務嗎？可在這裡要求報價',
          tip3: '還要再想想？沒有關係，你喜歡的都在這裡等你'
        },
        'en': {
          tip1: 'You can edit profile, password and adding common traveler',
          tip2: 'You can request a quotation for a unique group tour',
          tip3: 'All your favorite product can be found here'
        }
      }
    },
    components: {GuideItem},
    computed: {...mapGetters(['userType', 'isTravels', 'userGuide'])},
    data() {
      return {
        count: 0
      };
    },

    mounted() {
      const userGuide = window.localStorage.getItem('UserGuide');
      if (!userGuide) {
        this.$store.dispatch('guide/setUserGuide', '1');
      }
      // 定时任务 2秒调用一次
      // this.timer = setInterval(this.getNoticeMessageCount, 2000);
    },
    // 摧毁定时接口
    // destroyed: function() {
    //   clearInterval(this.timer);
    //   this.timer = null;
    // },
    created() {
      // this.getNoticeMessageCount();
    },
    methods: {
      getNoticeMessageCount() {
        const data = {isRead: 0};
        getNoticeMessageCount(data).then(res => {
          const {value} = res;
          this.count = value;
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .sidebar{
    min-height:800px;background-color:#f4f4f4;width: 200px;position: fixed;
    .item{
      border-bottom:1px dashed #e1e0df;
      a{display:block;line-height:68px;font-size:0;}
      .active{
        .label{color:#ff6f61;}
      }
      .icons{width:20px;height:20px;margin-left:51px;vertical-align:middle;}
      .label{display:inline-block;margin-left:16px;vertical-align:middle;color:#321908;font-size:20px;}
    }
  }
  .i-home{background-image:url(../assets/images/icon/home.png);}
  .i-coins{background-image: url(../assets/images/icon/coins-solid.png)};
  .i-trophy{background-image:url(../assets/images/icon/trophy.png);}
  .i-account{background-image:url(../assets/images/icon/account.png);}
  .i-group{background-image:url(../assets/images/icon/group1.png);}
  .i-order{background-image:url(../assets/images/icon/order.png);}
  .i-star{background-image:url(../assets/images/icon/star1.png);}
  .i-evaluation{background-image:url(../assets/images/icon/evaluation.png);}
  .i-bell{background-image:url(../assets/images/icon/bell.png);}
  .i-contact{background-image:url(../assets/images/icon/contact.png);}
  .i-location{background-image:url(../assets/images/icon/location.png);}
  .i-sortdown{background-image:url(../assets/images/icon/sort_down.png);}
  .i-tag{background-image:url(../assets/images/icon/tag.png);}
  .active{
    .i-home{background-image:url(../assets/images/icon/home_selected.png);}
    .i-account{background-image:url(../assets/images/icon/account_selected.png);}
    .i-group{background-image:url(../assets/images/icon/group1_selected.png);}
    .i-order{background-image:url(../assets/images/icon/order_selected.png);}
    .i-star{background-image:url(../assets/images/icon/star1_selected.png);}
    .i-evaluation{background-image:url(../assets/images/icon/evaluation_selected.png);}
    .i-bell{background-image:url(../assets/images/icon/bell_selected.png);}
    .i-contact{background-image:url(../assets/images/icon/contact_selected.png);}
    .i-location{background-image:url(../assets/images/icon/location_selected.png);}
    .i-sortdown{background-image:url(../assets/images/icon/sort_down.png);}
    .i-tag{background-image:url(../assets/images/icon/tag_selected.png);}
  }
</style>
