import Cookies from 'js-cookie';

export const languageOptions = [
  {name: '繁體中文', value: 'zh-hk'},
  {name: '简体中文', value: 'zh-cn'},
  {name: 'English', value: 'en'}
];

export function getLocale() {
  return Cookies.get('language');
}

export function setLocale(language) {
  return Cookies.set('language', language, {expires: 3650});
}
