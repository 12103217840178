<template>
  <div class="guide-box cl" v-if="isShow" @click.stop>
    <i class="i-arr-l"/>
    <div class="cont">
      <div>{{content}}</div>
      <div class="actions cl">
        <!--知道啦-->
        <a @click="hadlenNext">{{$t('btn1Name')}}</a>
        <!--跳過導覽-->
        <a class="minor" @click="setGuide('')">{{$t('btn2Name')}}</a>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import {scrollToY} from '../utils';

  export default {
    name: 'GuideItem',
    i18n: {
      messages: {
        'zh-cn': {
          btn1Name: '知道啦',
          btn2Name: '跳过导览'
        },
        'zh-hk': {
          btn1Name: '知道啦',
          btn2Name: '跳過導覽'
        },
        'en': {
          btn1Name: 'OK',
          btn2Name: 'Skip'
        }
      }
    },
    props: {
      content: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'user'
      },
      current: {
        type: String,
        default: ''
      },
      onNext: Function
    },
    computed: {
      ...mapGetters(['userGuide', 'adminGuide']),
      isShow() {
        if (this.type === 'user') {
          return this.current === this.userGuide;
        } else {
          return this.current === this.adminGuide;
        }
      }
    },
    watch: {
      userGuide(value) {
        this.guideScroll(value);
      },
      adminGuide(value) {
        this.guideScroll(value);
      }
    },
    methods: {
      setGuide(value) {
        if (this.type === 'user') {
          this.$store.dispatch('guide/setUserGuide', value);
          if (!value) {
            window.localStorage.setItem('UserGuide', '1');
          }
        } else {
          this.$store.dispatch('guide/setAdminGuide', value);
          if (!value) {
            window.localStorage.setItem('AdminGuide', '1');
          }
        }
      },
      hadlenNext() {
        if (typeof this.onNext === 'function') {
          this.onNext();
        } else {
          this.setGuide('');
        }
      },
      guideScroll(value) {
        if (value === this.current) {
          this.$nextTick(() => {
            const winH = document.body.clientHeight;
            const $guide = document.querySelector('.guide');
            let offsetTop = 0;
            let h = 0;
            if ($guide) {
              offsetTop = $guide.offsetTop;
              h = $guide.clientHeight;
            }
            scrollToY(undefined, offsetTop - (winH - h) / 2);
          });
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .guide-box{
    position:absolute;left:100%;top:20px;z-index:2001;width:380px;
    .cont{width:280px;margin-left:100px;color:#fff;font-size:15px;}
    .actions{
      margin-top:30px;
      a{
        display:block;float:left;width:132px;height:44px;margin-right:8px;line-height:44px;text-align:center;font-size:16px;border-radius:4px;pointer-events:auto;
        &.minor{color:#fff;background-color:rgba(244, 244, 244, 0.3);}
      }
    }
  }
  .i-arr-l{display:block;float:left;width:60px;height:30px;margin-left:20px;background-image:url(../assets/images/guide-arr-l.png);background-size:100% 100%;}
</style>
<style lang="less">
  .guide{
    position:relative;
    &:after{position:fixed;top:0;right:0;bottom:0;left:0;z-index:2000;background-color:rgba(0, 0, 0, 0.7);content:'';}
    a{position:relative;z-index:2001;background-color:#fff;pointer-events:none;}
  }
</style>
