import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import {getToken} from '../utils/auth';
import router from './index';
import store from '../store';
import {scrollToY} from '../utils';

let isLoadingUserInfo = false;

NProgress.configure({showSpinner: false});

// 验证访问权限
function accessPermission(to, from, next, pageType, roles) {
  if (pageType === 'user') {
    // 验证个人用户或团体用户的权限
    const userType = store.getters.userType;
    const isTravels = store.getters.isTravels === 'Y' ? 'travels' : ''; // 用于判断是否有【我的游记】权限
    if (roles.includes(userType) || roles.includes(isTravels)) {
      next();
    } else {
      next({name: 'Page401'});
    }
  } else {
    // 验证后台用户的权限
    let hasPermission = false;
    const menuUrl = store.getters.menuUrl;
    for (let item of roles) {
      if (menuUrl.includes(item)) {
        hasPermission = true;
        break;
      }
    }
    if (hasPermission) {
      next();
    } else {
      next({name: 'Page401'});
    }
  }
}

router.beforeEach((to, from, next) => {
  const pageType = to.fullPath.includes('/admin') ? 'admin' : 'user';
  const token = getToken(pageType === 'admin' ? 'adminToken' : undefined);
  const roles = to.meta.roles;
  document.querySelector('#app') && scrollToY();
  NProgress.start();

  if (!from.matched.length && !isLoadingUserInfo) {
    isLoadingUserInfo = true;
    store.dispatch('user/getInfo').then((res) => {
      if (roles && pageType === 'user') {
        accessPermission(to, from, next, pageType, roles);
      }
    });
    store.dispatch('adminUser/getAdminInfo');
    store.dispatch('adminUser/getMenuList').then((res) => {
      if (roles && pageType === 'admin') {
        accessPermission(to, from, next, pageType, roles);
      }
    });
    store.dispatch('adminUser/getRoleList');
  }
  // 授权验证
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({name: pageType === 'admin' ? 'AdminLogin' : 'Login', query: {redirect: to.fullPath}});
    } else {
      // 判断该页面是否有访问限制
      if (!roles) {
        next();
      } else {
        // 如果是商户端
        if (pageType === 'admin') {
          // 如果缓存已有角色类型
          if (store.getters.menuUrl.length) {
            accessPermission(to, from, next, pageType, roles);
          }
        } else {
          // 如果缓存有用户身份，则验证有没有访问权限；如果没有缓存，则需要等接口响应后再显示
          store.getters.userType && accessPermission(to, from, next, pageType, roles);
        }
      }
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});
