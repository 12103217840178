<template>
  <div class="tabs">
    <tab-nav :panes="panes" :on-tab-click="handleTabClick"/>
    <slot></slot>
  </div>
</template>
<script>
  import TabNav from './TabNav';

  export default {
    name: 'Tabs',
    props: {
      value: {}
    },
    data() {
      return {
        currentName: this.value,
        panes: []
      };
    },
    components: {TabNav},
    mounted() {
      this.calcPaneInstances();
    },
    updated() {
      this.calcPaneInstances();
    },
    watch: {
      value(value) {
        this.currentName = value;
      }
    },
    methods: {
      calcPaneInstances(isForceUpdate = false) {
        if (this.$slots.default) {
          const paneSlots = this.$slots.default.filter(vnode => vnode.tag && vnode.componentOptions && vnode.componentOptions.Ctor.options.name === 'TabPane');
          const panes = paneSlots.map(({componentInstance}) => componentInstance);
          const panesChanged = !(panes.length === this.panes.length && panes.every((pane, index) => pane === this.panes[index]));
          if (isForceUpdate || panesChanged) {
            this.panes = panes;
          }
        } else if (this.panes.length !== 0) {
          this.panes = [];
        }
      },
      handleTabClick(value) {
        this.currentName = value;
        this.$emit('input', value);
        this.$emit('tab-click', value);
      }
    }
  };
</script>
<style scoped lang="less">
</style>
