<template>
  <div class="tab-nav cl">
    <div class="item" v-for="(item,index) of panes"
         :class="{active:item.name===$parent.currentName,'is-disabled':item.isDisabled}"
         :key="item.name">
      <a @click="handleClick(index,item.name)">{{item.label}}</a>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'TabNav',
    props: {
      panes: Array,
      onTabClick: {
        type: Function,
        default: () => {
        }
      },
      isDisabled: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleClick(index, value) {
        if (this.panes[index].isDisabled) {
          const disabledMsg = this.panes[index].disabledMsg;
          if (disabledMsg) {
            this.$message.warning(disabledMsg);
          }
        } else {
          this.onTabClick(value);
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .tab-nav{
    margin-top:40px;border-bottom:1px dashed #feb7b0;user-select:none;
    .item{
      position:relative;z-index:1;float:left;width:25%;line-height:40px;
      a{display:block;min-width:110px;color:#321908;font-size:16px;cursor:pointer;}
      &:hover{
        a{color:#ff6f61;}
      }
      &.is-disabled{
        a{opacity:0.5;cursor:default;}
      }
      &.active{
        pointer-events:none;
        &:after{position:absolute;left:0;bottom:-1px;width:70px;height:4px;background-color:#ff6f61;content:'';}
      }
    }
  }
</style>
