import i18n from '../i18n';

// 旅客类型
const travelerType = {
  adultY: i18n.t('AdultPrice'),
  childY: i18n.t('ChildPrice'),
  childN: i18n.t('NotChildPrice'),
  babyN: i18n.t('BabyPrice')
};

// 会员等级
const memberType = {
  ordinary: i18n.t('Ordinary member'),
  star: i18n.t('Star Member'),
  gold: i18n.t('Golden Member'),
  diamond: i18n.t('Diamond Member')
};

// 性别
const gender = {M: i18n.t('Male'), F: i18n.t('Female')};

// 支付平台
const payPlatform = {
  paypal: 'PayPal',
  aliPC2Mobile: i18n.t('AlipayHK'),
  artificial: i18n.t('CashPayment')
};

let dicts = {
  'zh-cn': {
    travelerType,
    memberType,
    // 退款状态
    refundStatus: {
      CANCEL: '已取消',
      CHECK_REFUND: '退款审核中',
      REFUNDING: '退款中',
      OVER_REFUND: '完成退款',
      REFUSE_REFUND: '拒绝退款'
    },
    gender,
    // 订单来源
    orderSource: {pc: '平台订单', h5: '平台订单', cancel: '取消后新订单', mission: '调团后新订单', artificial: '人工订单'},
    // 取消订单类型
    cancelCategory: {
      order: '取消订单退回',
      total: '取消订单退回',
      plus: '商户补偿',
      deduction: '扣减费用',
      newOrder: '新订单衍生费用'
    },
    quoteStatus: {
      unjoin: '未发出报价',
      join: '已发出报价',
      select: '配对成功',
      notSelect: '配对失败'
    },
    // 游记状态
    travelsStatus: {edit: '未发布', release: '已发布', review: '审核中', refuse: '拒绝/下架'},
    // 支付平台
    payPlatform
  },
  'zh-hk': {
    travelerType,
    memberType,
    // 退款状态
    refundStatus: {
      CANCEL: '已取消',
      CHECK_REFUND: '退款審核中',
      REFUNDING: '退款中',
      OVER_REFUND: '完成退款',
      REFUSE_REFUND: '拒絕退款',
      REFUNDED: '已退款',
      NOT_APPLICABLE: '不適用'
    },
    gender,
    // 订单来源
    orderSource: {pc: '平台訂單', h5: '平台訂單', cancel: '取消後新訂單', mission: '調團後新訂單', artificial: '人工訂單'},
    // 取消订单类型
    cancelCategory: {
      order: '取消訂單退回',
      total: '取消訂單退回',
      plus: '商戶補償',
      deduction: '扣減費用',
      newOrder: '新訂單衍生費用'
    },
    quoteStatus: {
      unjoin: '未發出報價',
      join: '已發出報價',
      select: '配對成功',
      notSelect: '配對失敗'
    },
    // 游记状态
    travelsStatus: {edit: '未發布', release: '已發布', review: '審核中', refuse: '拒絕/下架'},
    // 支付平台
    payPlatform
  },
  'en': {
    travelerType,
    memberType,
    // 退款状态
    refundStatus: {
      CANCEL: '已取消',
      CHECK_REFUND: '退款審核中',
      REFUNDING: '退款中',
      OVER_REFUND: '完成退款',
      REFUSE_REFUND: '拒絕退款'
    },
    gender,
    // 订单来源
    orderSource: {pc: '平台訂單', h5: '平台訂單', cancel: '取消後新訂單', mission: '調團後新訂單', artificial: '人工訂單'},
    // 取消订单类型
    cancelCategory: {
      order: '取消訂單退回',
      total: '取消訂單退回',
      plus: '商戶補償',
      deduction: '扣減費用',
      newOrder: '新訂單衍生費用'
    },
    quoteStatus: {
      unjoin: '未發出報價',
      join: '已發出報價',
      select: '配對成功',
      notSelect: '配對失敗'
    },
    // 游记状态
    travelsStatus: {edit: '未發布', release: '已發布', review: '審核中', refuse: '拒絕/下架'},
    // 支付平台
    payPlatform
  }
};

export default dicts[i18n.locale];
