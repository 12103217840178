<template>

  <div>
    <!-- The Modal -->
<!--    <div id="myModal" class="modal" v-if="showModal">-->
<!--      &lt;!&ndash; The Close Button &ndash;&gt;-->
<!--      <span class="close" @click="toggleModal">&times;</span>-->

<!--      &lt;!&ndash; Modal Content (The Image) &ndash;&gt;-->
<!--      <img-->
<!--        class="modal-content"-->
<!--        id="img01"-->
<!--        src="../../assets/images/home/voucher.png"-->
<!--      />-->
<!--      &lt;!&ndash; Modal Caption (Image Text) &ndash;&gt;-->
<!--      <div id="caption"></div>-->
<!--    </div>-->
    <banner :banners="banners" />
    <hot-products :list.sync="products" />
    <featured-itinerary :list="groups" />
    <div class="layout">
      <promotions :list="promotions" />
      <star-merchant :list="businesses" />
      <popular-destination :list="cityRecommends" />
      <classification :list="classificationRecommends" />
    </div>
    <div class="loginButton" style="z-index: 2">
      <a href="https://www.unutravel.com/admin/login">
        <img
          src="../../assets/images/login_flowing_button.svg"
          style="width: 300px; height: 100px"
        />
      </a>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import googleOneTap from 'google-one-tap';
import Banner from './components/Banner';
import StarMerchant from './components/StarMerchant';
import FeaturedItinerary from './components/FeaturedItinerary';
import HotProducts from './components/HotProducts';
import PopularDestination from './components/PopularDestination';
import Promotions from './components/Promotions';
import Classification from './components/Classification';
import saveScrollPosition from '../../mixins/saveScrollPosition';
import { getHomeData } from '../../api/home';

export default {
  name: 'Home',
  data() {
    return {
      banners: [],
      cityRecommends: [],
      promotions: [],
      businesses: [],
      groups: [],
      products: [],
      classificationRecommends: [],
      showModal: true
    };
  },
  components: {
    Banner,
    StarMerchant,
    FeaturedItinerary,
    HotProducts,
    PopularDestination,
    Promotions,
    Classification
  },
  mixins: [saveScrollPosition],
  activated() {
    this.getHomeData();
  },
  methods: {
    // 获取首页数据
    getHomeData() {
      getHomeData().then((res) => {
        Object.assign(this.$data, res.value || {});
      });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    }
  },
  computed: {...mapGetters(['token'])},
  mounted() {
    const options = {
      client_id: '1089313489941-mqesnfntuit2d7h6h3brlgimivjvh63l.apps.googleusercontent.com', // required
      auto_select: false, // optional
      cancel_on_tap_outside: false, // optional
      context: 'signin', // optional
      token: this.token
    };
    googleOneTap(options, (response) => {
      // Send response to server
      let data = {loginType: 'googleOneTap', credential: response.credential};
      const loading = this.$loading({text: 'Loading'});
      this.$store.dispatch('user/login', data).then(() => {
        this.$store.dispatch('user/getInfo').then(() => {
          loading.close();
          this.$router.replace(this.redirect ? {path: this.redirect} : {name: 'My'});
        });
      }).catch(() => {
        loading.close();
      });
    });
  }
};
</script>
<style scoped lang="less">
/* Style the Image Used to Trigger the Modal */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 30000000; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  height: 85%;
  width: 85%;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

.loginButton {
  color: #717375;
  position: fixed;
  right: 40%;
  text-align: center;
  top: 730px;
}
/* Add Animation - Zoom in the Modal */
.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 100px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .loginButton {
    display: none;
  }
}
</style>
