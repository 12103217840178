<template>
  <div class="featured-itinerary">
    <div class="layout">
      <div class="hd">{{$t('FeaturedTheme')}}</div>
      <div class="center-box">
        <router-link target="_blank" class="item" :to="{name:'FeaturedDetail',query:{id:item.groupId}}" v-for="item of list" :key="item.groupId">
          <div class="cover">
            <img class="img" :src="item.photoUrl" alt=""/>
          </div>
          <div class="infos">{{item.title}}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'FeaturedItinerary',
    props: {
      list: Array
    }
  };
</script>
<style scoped lang="less">
  .center-box{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .featured-itinerary{
    margin-top:60px;padding:60px 0;background-image:linear-gradient(45deg, rgba(250, 215, 161, 0.2) 0%, rgba(254, 111, 96, 0.2) 100%);
    .hd{margin-bottom:30px;text-align:center;font-size:24px;}
    .item{
      width:300px; margin-right:15px; margin-left:15px;margin-top:30px; cursor:pointer;
      .cover{height:120px;overflow:hidden;border-radius:4px;}
      .infos{margin-top:10px;text-align:center;color:#321908;font-size:16px;}
    }
    .btn-prev,
    .btn-next{
      position:absolute;top:0;bottom:0;left:0;z-index:2;width:150px;padding-top:48px;cursor:pointer;
      &.swiper-button-disabled{display:none;}
    }
    .btn-prev{text-align:left;background-image:linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);}
    .btn-next{left:auto;right:0;text-align:right;background-image:linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);}
  }
@media only screen and (max-width: 768px) {
    .item {
      width:800px;
    }

   }
</style>
