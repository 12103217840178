import request from '@/utils/request';
import {getLocale} from '../utils/language';
import {getCurrency} from '../utils';

const language = getLocale() || 'zh-hk';
const currency = getCurrency();

// 获取首页数据
export function getHomeData() {
  return request({
    url: `/index/${language}/pc`,
    method: 'get',
    params: {currencyCode: currency}
  });
}

// 获取限时推广详情
export function getPromotionsDetail(promotionId) {
  return request({
    url: `/promotion/details/${promotionId}/${language}`,
    method: 'get'
  });
}

// 获取banner详情
export function getBannerDetail(bannerId) {
  return request({
    url: `/index/home/banner/details/${bannerId}/${language}`,
    method: 'get'
  });
}
