<template>
  <div class="tab-pane" v-if="active">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'TabPane',
    props: {
      label: String,
      name: String,
      isDisabled: Boolean,
      disabledMsg: String
    },
    data() {
      return {};
    },
    computed: {
      active() {
        const active = this.$parent.currentName === this.name;
        return active;
      }
    },
    methods: {}
  };
</script>
<style scoped lang="less">
  .tab-pane{animation-name:fadeIn;animation-duration:0.6s;animation-fill-mode:backwards;}
  @keyframes fadeIn{
    0%{opacity:0;transform:translateY(20px);}
    100%{opacity:1;transform:translateY(0);}
  }
</style>
