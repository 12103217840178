import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 注册
export function adminRegister(data) {
  return request({
    url: '/admin/user/register',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 登录
export function adminLogin(data) {
  return request({
    url: '/admin/user/login',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 登出
export function adminLogout() {
  return request({
    url: '/admin/user/logout',
    baseURL: '/v2',
    method: 'post'
  });
}

// 修改头像
export function adminUpdatePhoto(data) {
  return request({
    url: '/admin/user/update/photo',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 修改密码
export function adminUpdatePassword(data) {
  return request({
    url: '/admin/user/update/password',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 获取商户信息
export function getAdminInfo() {
  return request({
    url: `/admin/user/my/info/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 修改商户信息
export function updateAdminInfo(data) {
  return request({
    url: '/admin/user/update/my/info',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 获取联络人信息
export function getContactInfo() {
  return request({
    url: '/admin/user/contact',
    baseURL: '/v2',
    method: 'get'
  });
}

// 修改联络人信息
export function updateContactInfo(data) {
  return request({
    url: '/admin/user/update/contact',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 保存管理账号
export function SaveAccount(data) {
  return request({
    url: '/admin/user/save',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 删除管理账号
export function deleteAccount(userId) {
  return request({
    url: `/admin/user/delete/${userId}`,
    baseURL: '/v2',
    method: 'post'
  });
}

// 获取管理账号
export function getAccountDetail(userId) {
  return request({
    url: `/admin/user/details/${userId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 获取管理账号列表（分页）
export function getAccountList(data) {
  return request({
    url: '/admin/user/list',
    baseURL: '/v2',
    method: 'get',
    params: data
  });
}

// 获取用户菜单
export function getMenuList() {
  return request({
    url: `/admin/user/menu/list/${language}`,
    baseURL: '/v2',
    method: 'get'
  });
}
