const getters = {
  token: state => state.user.token,
  userId: state => state.user.userId,
  avatar: state => state.user.avatar,
  nickname: state => state.user.nickname,
  userType: state => state.user.userType,
  isTravels: state => state.user.isTravels,
  adminToken: state => state.adminUser.token,
  adminUserId: state => state.adminUser.userId,
  companyName: state => state.adminUser.companyName,
  legalCompanyName: state => state.adminUser.legalCompanyName,
  adminUserName: state => state.adminUser.userName,
  adminAvatar: state => state.adminUser.avatar,
  memberType: state => state.adminUser.memberType,
  memberDate: state => state.adminUser.memberDate,
  roleType: state => state.adminUser.roleType,
  paymentCurrency: state => state.adminUser.paymentCurrency,
  roleList: state => state.adminUser.roleList,
  menuList: state => state.adminUser.menuList,
  menuUrl: state => state.adminUser.menuUrl,
  userGuide: state => state.guide.userGuide,
  adminGuide: state => state.guide.adminGuide
};
export default getters;
