<template>
  <div class="modal-wrap" v-if="isShow">
    <div class="modal-content" >
      <slot name="header">
        <div class="hd cl">
          <div class="fl title">{{title}}</div>
          <a class="fr btn-close" href="javascript:;" @click="close">
            <img src="../assets/images/icon/close.png"/>
          </a>
        </div>
      </slot>
      <slot></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Modal',
    props: {
      isShow: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      width: {
        type: String,
        default: '690px'
      }
    },
    methods: {
      close() {
        this.$emit('close-modal');
      }
    }
  };
</script>
<style scoped lang="less">
  .modal-wrap{
    position:fixed;top:0;right:0;bottom:0;left:0;z-index:1800;text-align:center;background-color:rgba(0, 0, 0, 0.5);
    &:after{display:inline-block;height:100%;vertical-align:middle;content:'';}
  }
  .modal-content{
    display:inline-block;position:relative;width: 100vw; max-width: 600px; max-height:80vh;overflow-y:auto;vertical-align:middle;background-color:#fff;animation-name:zoomIn;animation-duration:.4s;animation-fill-mode:backwards;
    &::-webkit-scrollbar{width:6px;}
    &::-webkit-scrollbar-thumb{border-radius:3px;background:#c1c1c1;}
    .hd{position:sticky;top:0;z-index:1000;padding:24px 30px;border-bottom:1px solid #d9d9d9;background-color:#fff;}
    .title{font-size:24px;}
    .btn-close{margin-top:6px;}
  }
  @keyframes zoomIn{
    0%{opacity:0;transform:scale(0)}
    50%{opacity:1;}
    100%{opacity:1;transform:scale(1)}
  }
</style>
