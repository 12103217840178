<template>
  <div class="container layout admin">
    <div class="col-main">
      <transition name="fade">
        <router-view/>
      </transition>
    </div>
    <div class="col-menu">
      <sidebar/>
    </div>
  </div>
</template>
<script>
  import Sidebar from '../components/admin/Sidebar';

  export default {
    name: 'AdminPage',
    components: {Sidebar}
  };
</script>
<style scoped lang="less">

.container.layout.admin{
width:90%;

}
  .container{
    //margin-top:30px;
    zoom:1;
    &:after,
    &:before{display:table;content:"";}
    &:after{clear:both;}
    > .col-menu{float:left;width:220px;min-height:800px;margin-left:-100%;background-color:#f4f4f4;}
    > .col-main{
      float:left;width:100%;min-height:800px;
      > .main{
        position:relative;margin-left:250px;
        &.m-l{margin-left:280px;}
      }
    }
  }
  .fade-enter-active{transition:all .6s;}
  .fade-enter{opacity:0;transform:translateY(20px);}
</style>
