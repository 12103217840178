import i18n from '../../i18n';
import {getToken, setToken, removeToken} from '../../utils/auth';
import {adminLogin, adminLogout, getAdminInfo, adminUpdatePhoto, getMenuList} from '../../api/admin-user';
import {getAdminAllRoleList} from '../../api/admin-role';

const state = {
  token: getToken('adminToken'),
  userId: '',
  companyName: '',
  legalCompanyName: '',
  userName: window.localStorage.getItem('adminUserName') || '',
  avatar: window.localStorage.getItem('adminAvatar') || '',
  memberType: '',
  memberDate: '',
  roleType: window.localStorage.getItem('roleType') || '',
  paymentCurrency: '',
  roleList: [],
  menuList: [],
  menuUrl: []
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId;
  },
  SET_COMPANY_NAME: (state, companyName) => {
    state.companyName = companyName;
  },
  SET_LEGAL_COMPANYNAME: (state, legalCompanyName) => {
    state.legalCompanyName = legalCompanyName;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar || '';
    window.localStorage.setItem('adminAvatar', avatar || '');
  },
  SET_USER_NAME: (state, userName) => {
    state.userName = userName;
  },
  SET_MEMBER_TYPE: (state, memberType) => {
    state.memberType = memberType;
  },
  SET_MEMBER_DATE: (state, memberDate) => {
    state.memberDate = memberDate;
  },
  SET_ROLE_TYPE: (state, roleType) => {
    state.roleType = roleType;
  },
  SET_PAYMENT_CURRENCY: (state, paymentCurrency) => {
    state.paymentCurrency = paymentCurrency;
  },
  SET_ROLE_LIST: (state, roleList) => {
    state.roleList = roleList;
  },
  SET_MENU_LIST: (state, menuList) => {
    state.menuList = menuList;
  },
  SET_MENU_URL: (state, menuUrl) => {
    state.menuUrl = menuUrl;
  }
};

const actions = {
  // 登录
  login({commit, state}, data) {
    return new Promise((resolve, reject) => {
      adminLogin(data).then(res => {
        const {value} = res;
        const {token, userId, companyName, userName, photoUrl, memberType, memberDate, roleType, paymentCurrency} = value;

        commit('SET_TOKEN', token);
        commit('SET_USER_ID', userId);
        commit('SET_COMPANY_NAME', companyName);
        commit('SET_USER_NAME', userName);
        commit('SET_AVATAR', photoUrl);
        commit('SET_MEMBER_TYPE', memberType);
        commit('SET_MEMBER_DATE', memberDate);
        commit('SET_ROLE_TYPE', roleType);
        commit('SET_PAYMENT_CURRENCY', paymentCurrency);
        setToken(token, 'adminToken');
        window.localStorage.setItem('adminUserName', userName);
        window.localStorage.setItem('roleType', roleType);
        resolve(res);
      }).catch(error => {
        reject(error);
      });
    });
  },

  // 登出
  logout({commit, state}) {
    return new Promise((resolve, reject) => {
      adminLogout().then(() => {
        commit('SET_TOKEN', '');
        removeToken('adminToken');
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  // 获取我的信息
  getAdminInfo({commit, state}) {
    return new Promise((resolve, reject) => {
      if (state.token) {
        getAdminInfo().then(res => {
          const {value} = res;
          const {userId, companyName, legalCompanyName, memberType, memberDate, paymentCurrency} = value;

          commit('SET_USER_ID', userId);
          commit('SET_COMPANY_NAME', companyName);
          commit('SET_LEGAL_COMPANYNAME', legalCompanyName);
          commit('SET_MEMBER_TYPE', memberType);
          commit('SET_MEMBER_DATE', memberDate);
          commit('SET_PAYMENT_CURRENCY', paymentCurrency);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
      } else {
        reject(new Error('Invalid Token'));
      }
    });
  },

  // 更新头像
  updateAvatar({commit, state}, avatar) {
    if (avatar) {
      adminUpdatePhoto({photoUrl: avatar}).then(res => {
        commit('SET_AVATAR', avatar);
      });
    }
  },

  // 更新昵称
  updateNickname({commit, state}, nickname) {
    commit('SET_NICKNAME', nickname);
  },

  // 获取用户角色
  getMenuList({commit, state}) {
    return new Promise((resolve, reject) => {
      if (state.token) {
        getMenuList().then(res => {
          const {value} = res || [];
          let menuUrl = [];
          for (let item of value) {
            item.link && menuUrl.push(item.link);
            for (let cItem of item.childrenList || []) {
              cItem.link && menuUrl.push(cItem.link);
            }
          }
          commit('SET_MENU_LIST', value);
          commit('SET_MENU_URL', menuUrl);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
      } else {
        reject(new Error('Invalid Token'));
      }
    });
  },

  // 获取角色列表
  getRoleList({commit, state}) {
    return new Promise((resolve, reject) => {
      if (state.token) {
        getAdminAllRoleList().then(res => {
          let value = res.value || [];
          value.push({id: 'root', name: i18n.t('Owner')});
          commit('SET_ROLE_LIST', value);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
      } else {
        reject(new Error('Invalid Token'));
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
