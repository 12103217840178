import {getToken, setToken, removeToken, setUserId, removeUserId, getUserId} from '../../utils/auth';
import {login, logout, getMyInfo, updateAvatar} from '../../api/user';

const state = {
  token: getToken(),
  userId: getUserId(),
  nickname: '',
  avatar: '',
  userType: '',
  isTravels: ''
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId;
  },
  SET_NICKNAME: (state, name) => {
    state.nickname = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_USER_TYPE: (state, userType) => {
    state.userType = userType;
  },
  SET_IS_TRAVELS: (state, isTravels) => {
    state.isTravels = isTravels;
  }
};

const actions = {
  // 登录
  login({commit, state}, data) {
    return new Promise((resolve, reject) => {
      login(data).then(res => {
        const {value} = res;
        const {token, userId, nickName, photoUrl, userType, isTravels} = value;

        commit('SET_TOKEN', token);
        commit('SET_USER_ID', userId);
        commit('SET_NICKNAME', nickName);
        commit('SET_AVATAR', photoUrl);
        commit('SET_USER_TYPE', userType);
        commit('SET_IS_TRAVELS', isTravels);
        setToken(token);
        setUserId(userId);
        resolve(res);
      }).catch(error => {
        reject(error);
      });
    });
  },

  // 登出
  logout({commit, state}) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        commit('SET_TOKEN', '');
        removeToken();
        removeUserId();
        resolve();
        FB && FB.logout(); // Facebook退出登录
      }).catch(error => {
        reject(error);
      });
    });
  },

  // 获取我的信息
  getInfo({commit, state}) {
    return new Promise((resolve, reject) => {
      if (state.token) {
        getMyInfo().then(res => {
          const {value} = res;
          const {id, nickName, photoUrl, userType, isTravels} = value;

          commit('SET_USER_ID', id);
          commit('SET_NICKNAME', nickName);
          commit('SET_AVATAR', photoUrl);
          commit('SET_USER_TYPE', userType);
          commit('SET_IS_TRAVELS', isTravels);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
      } else {
        reject(new Error('Invalid Token'));
      }
    });
  },

  // 更新头像
  updateAvatar({commit, state}, avatar) {
    if (avatar) {
      updateAvatar({photoUrl: avatar}).then(res => {
        commit('SET_AVATAR', avatar);
      });
    }
  },

  // 更新昵称
  updateNickname({commit, state}, nickname) {
    commit('SET_NICKNAME', nickname);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
