export {getCurrency, setCurrency} from './currency';

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T'];

/**
 * 格式化时间
 * */
export function formatDate(timestamp, format = 'yyyy-MM-dd hh:mm:ss') {
  if (!timestamp) return '';
  let date = typeof timestamp === 'number' ? new Date(timestamp) : new Date(timestamp.replace(/-/g, '/'));
  if (date && !isNaN(date.getTime())) {
  } else {
    return timestamp;
  }

  let fmt = format;
  let o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S': date.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    }
  }
  // var tz = moment.tz.guess();
  // moment.tz.guess(true);
  // console.log('timestamp:', fmt);
  // console.log('tz', tz);
  // console.log('转换后的时间：', moment(timestamp).utc(timestamp).tz(tz).format('YYYY-MM-DD HH:mm'));
  // return moment(timestamp).utc(timestamp).tz(tz).format('YYYY-MM-DD HH:mm');
  return fmt;
}

/**
 * 数字转字母
 * */
export function numberToLetter(number) {
  return letters[number] || number;
}

export function getNextLetter(letter) {
  return letters[letters.indexOf(letter) + 1];
}

/**
 * 格式化时间数字
 * */
export const formatNumber = n => {
  n = n.toString();
  return n[1] ? n : '0' + n;
};

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

/**
 * 检查Date对象是否为Invalid Date
 * */
export function isValidDate(date) {
  return date instanceof Date && !isNaN(date.getTime());
}

/**
 * 获取相邻元素
 * @param ele 参考物元素
 * @param type 类型，上一个(1)or下一个(0)
 * @return 返回查找到的元素Dom对象，无则返回null
 */
export function getNearEle(ele, type) {
  if (ele) {
    type = type === 1 ? 'previousSibling' : 'nextSibling';
    var nearEle = ele[type];
    while (nearEle) {
      if (nearEle.nodeType === 1) {
        return nearEle;
      }
      nearEle = nearEle[type];
      if (!nearEle) {
        break;
      }
    }
  }
  return null;
}

/**
 * 滚动到
 * */
export function scrollToY(el = document.getElementById('app'), y = 0) {
  window.scrollTo({top: 0, behavior: 'smooth'});
  // const beginTime = Date.now();
  // const beginValue = el.scrollTop;
  // const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16));
  // const frameFunc = () => {
  //   const progress = (Date.now() - beginTime) / 500;
  //   if (progress < 1) {
  //     el.scrollTop = beginValue + (y - beginValue) * progress;
  //     rAF(frameFunc);
  //   } else {
  //     el.scrollTop = y;
  //   }
  // };
  // rAF(frameFunc);
}

/**
 * 货币符号
 * */
export function currencySymbol(code) {
  return {rmb: 'CNY', gb: 'HKD', my: 'USD', oy: 'EUR', ry: 'JPY'}[code] || code;
}

/**
 * 排序政策
 * */
export function sortPolicy(datas) {
  const list = datas.sort(function (a, b) {
    if (b.position === 'end') {
      return -1;
    } else {
      return ~~b.maxDay - ~~a.maxDay;
    }
  });
  return list;
}
