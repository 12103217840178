<template>
  <div class="hot-products">
    <div class="hd">{{$t('Hot products')}}</div>
    <div class="bd cl center-box">
      <router-link class="item ani-scale"
           v-for="(item,index) of list"
           :key="item.productId"
           :to="{name:'TourismDetail',params:{id:item.productId}}">
        <div class="cover">
          <img class="img" :src="item.photoUrl" alt=""/>
          <a class="btn-collect" href="javascript:;"
             @click.stop="setCollection(index,item.productId,item.isCollection)">
            <img src="../../../assets/images/icon/heart-full.png" v-if="item.isCollection==='Y'"/>
            <img src="../../../assets/images/icon/heart.png" v-else/>
          </a>
        </div>
        <div class="infos">
          <div class="cl">
            <star class="star1" :value="item.pointNumber|starToPercentage"/>
            <div class="travel-agency">{{item.businessName}}</div>
          </div>
          <div class="title">{{item.title}}</div>
        </div>
        <div class="price-wrap cl">
<!--          <i class="icons i-comment"></i>-->
<!--          <span class="fcs1">{{$t('Comment')}}:{{item.commentNumber|interceptNumber}}</span>-->
<!--          <i class="icons i-cart"></i>-->
<!--          <span class="fcs1">{{$t('Buy')}}:{{item.buyNumber|interceptNumber}}</span>-->
          <span class="price">
            <span class="unit">{{item.billingType|currencySymbol}}</span>{{item.productPrice}}+
          </span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import Star from '@/components/Star';
  import {productCollection, cancelCollection} from '../../../api/collection';

  export default {
    name: 'HotProducts',
    props: {
      list: Array
    },
    computed: {...mapGetters(['token'])},
    components: {Star},
    methods: {
      // 收藏/取消收藏
      setCollection(index, productId, isCollection) {
        if (!this.token) {
          this.$message.warning(this.$t('Please sign in'));
          this.$router.push({name: 'Login', query: {redirect: this.$route.fullPath}});
        } else {
          const list = [...this.list];
          if (isCollection === 'Y') {
            // 取消收藏
            cancelCollection(productId).then(res => {
              list[index].isCollection = 'N';
              this.$emit('update:list', list);
            }).catch(() => {
            });
          } else {
            // 收藏
            productCollection(productId).then(res => {
              list[index].isCollection = 'Y';
              this.$emit('update:list', list);
            }).catch(() => {
            });
          }
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .center-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .hot-products{
    .hd{margin-top:60px;text-align:center;font-size:24px;}
    .item{
      float:left;width:300px;margin-top:30px;margin-right:15px;margin-left:15px;overflow:hidden;cursor:pointer;color:#321908;border-radius:4px;box-shadow:0 2px 8px 0 rgba(255, 115, 101, 0.20);min-height: 355px;
    }
    .cover{position:relative;height:200px;overflow:hidden;}
    .infos{padding:16px;}
    .btn-collect{position:absolute;top:16px;right:16px;}
    .star1{float:left;margin-left:-4px;}
    .travel-agency{margin-left:120px;text-align:right;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
    .title{display:-webkit-box;height:50px;margin-top:10px;line-height:25px;overflow:hidden;-webkit-line-clamp:2;-webkit-box-orient:vertical;font-size:16px;}
    .price-wrap{margin:0 8px 20px;}
    .price{float:right;line-height:1;color:#ff6f61;font-size:22px;}
    .unit{color:#666;font-size:14px;}
    .fcs1{display:inline-block;vertical-align:middle;color:#999;}
  }
  .icons{margin-right:4px;vertical-align:middle;}
  .i-comment{width:16px;height:16px;background-image:url('../../../assets/images/icon/comment.png')}
  .i-cart{width:16px;height:16px;margin-left:8px;background-image:url('../../../assets/images/icon/shopping-cart.png')}
</style>
